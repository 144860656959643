import React, { memo, useEffect, useState } from "react";
import { Avatar, Button, Dialog, DialogTitle, IconButton, Skeleton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { doc, getDoc, updateDoc, collection, addDoc, serverTimestamp, query, where, getDocs } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { db, auth, storage } from "../api/firebase";
import { useNotification } from "../redux/useNotification";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Linkify from "linkify-react";
import DotClickMenuAtPost from "./DotClickMenuAtPost";
import ModalForm from "./ModalForm";
import InputMediaOnFeed from "./InputMediaOnFeed";
import PostCommentsCount from "./PostCommentsCount";
import LikeAction from "./LikeAction";
import DisplayPostOnDDP from "./DisplayPostOnDDP";
import PostCommentsPreview from "./PostCommentsPreview";
import GetUserPrefecture from "./GetUserPrefecture";
import ImageRenderer from "./ImageRenderer";
import PostComments from "./PostComments";
import InputPostComment from "./InputPostComment";
import DialogContentComponent from "./DialogContentComponent"; // 新しいコンポーネントをインポート

const DisplayPost = ({ post }) => {
  const linkifyOptions = { className: "text-blue-400", target: "blank" };
  const { id } = useParams();
  const { displayNotification } = useNotification();
  const [postor, setPostor] = useState("");
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState("");
  const [openModal, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const [photos, setPhotos] = useState([]);
  const [data, setData] = useState({ message: "", error: "", loading: false });
  const { error, loading } = data;
  const [showFullMessage, setShowFullMessage] = useState(true);
  const [openDialog, setOpenDialog] = useState(false); // Dialogの開閉状態を管理する
  const [likeLoading, setLikeLoading] = useState(true); // LikeActionの読み込み状態
  const [commentsLoading, setCommentsLoading] = useState(true); // PostCommentsの読み込み状態
  const navigate = useNavigate();
  const location = useLocation();
  const showMoreLink = !location.pathname.includes("/post");
  const segments = location.pathname.split("/");
  const firstSegment = segments[1];

  useEffect(() => {
    getDoc(doc(db, "users", post.creator)).then((elm) => {
      setPostor({ id: elm.id, ...elm.data() });
    });

    const fetchPostImages = async () => {
      const q = query(collection(db, "postImage"), where("id", "==", post.id));
      const querySnapshot = await getDocs(q);
      const fetchedPhotos = querySnapshot.docs.map(doc => doc.data());
      setPhotos(fetchedPhotos);
    };

    fetchPostImages();
  }, [post.creator, post.id]);

  useEffect(() => {
    const init = async () => {
      if (post?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists() && userDoc.data().isAdmin) {
        setIsAdmin(true);
      }
    };
    post && init();
  }, [post]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setUser({ id: userDoc.id, ...userDoc.data() });
        } else {
          console.error("ユーザー情報が見つかりませんでした");
        }
      } catch (error) {
        console.error("ユーザー情報の取得中にエラーが発生しました", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  const doRegist = async (e) => {
    e.preventDefault();
    setData({ ...data, error: "", loading: true });

    try {
      const docRef = doc(db, "post", post?.id);
      const docData = { updatedAt: serverTimestamp() };
      await updateDoc(docRef, docData);

      if (photos.length > 0) {
        const photoPromises = photos.map(async (file) => {
          const ImageRef = ref(storage, `feed/${post?.id} - ${file.file.name}`);
          const snap = await uploadBytes(ImageRef, file.file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
          await addDoc(collection(db, "postImage"), {
            url,
            path: snap.ref.fullPath,
            id: post.id,
            isPublish: true,
            creator: auth.currentUser.uid,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            type: "post",
          });
          return { url, path: snap.ref.fullPath };
        });

        const uploadedPhotos = await Promise.all(photoPromises);
        setPhotos(uploadedPhotos);
      }

      setPhotos([]);
      displayNotification({ message: "投稿しました。", type: "success" });
      setData({ ...data, error: null });
      setOpen(false);
      window.location.reload();
    } catch (err) {
      console.log(err.message);
      const errorData = { message: err.message, stack: err.stack };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        component: "AddPost.js",
      });

      setData({ ...data, error: err.message, loading: false });
    }
  };

  const handleMoveToUser = () => {
    navigate("/user/" + postor?.id);
  };

  useEffect(() => {
    const newlineCount = (post.message.match(/\n/g) || []).length;
    if (newlineCount > 5) {
      setShowFullMessage(false);
    }
  }, [post.message, post.id]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setLikeLoading(true);
    setCommentsLoading(true);
    // Simulate API call or data fetching
    setTimeout(() => {
      setLikeLoading(false);
      setCommentsLoading(false);
    }, 1000); // Adjust the timeout as needed
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const targetElement = document.getElementById(post.id);
  useEffect(() => {
    if (targetElement) {
      setTimeout(() => {
        if (targetElement.scrollHeight > targetElement.offsetHeight) {
          setShowFullMessage(false);
        }
      }, 0);
    }
  }, [targetElement]);

  if (post?.type === "ddp") {
    return <DisplayPostOnDDP post={post} />;
  }

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={openModal}
        requestId={requestId}
        target={post}
      />

      <div className="max-w-3xl mx-2 my-5" style={{ filter: user?.isVillager ? "none" : "blur(4px)" }}>
        <div className="flex justify-between border-x border-t rounded-t-md p-3 hover:cursor-pointer duration-150 bg-white">
          <div className="flex space-x-2 items-center">
            <Avatar src={postor?.avatar} />
            <div>
              <div className="flex space-x-1 items-center">
                <div className="font-bold whitespace-pre-wrap break-words line-clamp-1 cursor-pointer hover:underline" onClick={handleMoveToUser}>
                  {postor?.lastName + postor?.firstName}
                </div>
                <div className="text-xs text-gray-500">
                  @<GetUserPrefecture userId={postor?.id} />
                </div>
              </div>
              {post?.updatedAt && (
                <div className="text-gray-300 text-xs">
                  {formatDistanceToNow(post?.updatedAt.toDate(), { locale: ja })}
                  前
                </div>
              )}
            </div>
          </div>
          <div className="p-2">
            {isAuthor  || isAdmin && <DotClickMenuAtPost post={post} />}
          </div>
        </div>

        <div className={`relative px-2 pt-3 border-x duration-150 whitespace-pre-wrap break-all leading-normal ${showMoreLink ? "line-clamp-6" : ""} text-gray-600 bg-white`} id={post.id}>
          <Linkify options={linkifyOptions}>
            {post.message.replace(/[ \t]+/g, "").replace(/　/g, "")}
          </Linkify>
        </div>
        <div>
          {!showFullMessage && showMoreLink && (
            <div className="border-r border-l bg-white flex justify-end items-center pr-4">
              <span className="w-fit h-5 text-gray-500 hover:font-bold hover:cursor-pointer hover:underline text-sm" onClick={handleDialogOpen}>
                もっと読む
              </span>
            </div>
          )}
        </div>

        <div className="duration-150 border-x whitespace-pre-wrap break-words line-clamp-6 bg-white">
          <ImageRenderer images={photos} />
        </div>

        <div className={` ${firstSegment !== "post" ? "  " : " "} p-2 duration-150 border-x bg-white justify-between grid grid-cols-2 gap-2`}>
          <LikeAction post={post} />
          <PostCommentsCount post={post} onClick={handleDialogOpen} />
        </div>

        {id && firstSegment === "post" && (
          <div className="group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words bg-white h-auto">
            <PostComments post={post} />
          </div>
        )}

        {!id && (
          <div className="group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words bg-white border-b rounded-b-md">
            <PostCommentsPreview post={post} />
          </div>
        )}

        {id && firstSegment === "group" && (
          <div className="group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words bg-white border-b rounded-b-md">
            <PostCommentsPreview post={post} />
          </div>
        )}

        {firstSegment === "post" && (
          <div className="p-5 border-t rounded-b-md duration-150 border-x border-b whitespace-pre-wrap break-words line-clamp-6 bg-white">
            <InputPostComment post={post} />
          </div>
        )}
        {firstSegment === "user" && (
          <>
            <div className="border-x border-b rounded-b-md h-5 bg-white"></div>
          </>
        )}
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md" PaperProps={{ style: { margin: 0, width: '100%' } }}>
        <DialogTitle>
          投稿の詳細
          <IconButton aria-label="close" onClick={handleDialogClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContentComponent
          post={post}
          postor={postor}
          isAuthor={isAuthor}
          likeLoading={likeLoading}
          commentsLoading={commentsLoading}
          handleMoveToUser={handleMoveToUser}
        />
      </Dialog>
    </>
  );
};

export default memo(DisplayPost);
