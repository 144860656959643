// src/components/SkillExtractor.js

import React, { useState } from 'react';
import { Box, Button, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { getSkillDetails } from '../api/chatgpt';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SkillExtractor = ({ experienceText, userName, onSkillsExtracted }) => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleExtractSkills = async () => {
    if (!experienceText.trim() || !userName.trim()) {
      setSnackbar({ open: true, message: '経歴と名前を入力してください。', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const skills = await getSkillDetails(experienceText, userName);
      if (typeof skills === 'string') {
        setSnackbar({ open: true, message: skills, severity: 'error' });
      } else {
        onSkillsExtracted(skills); // 抽出結果を親に渡す
      }
    } catch (error) {
      setSnackbar({ open: true, message: '能力の抽出に失敗しました。再度お試しください。', severity: 'error' });
    }
    setLoading(false);
  };

  return (
    <Box mt={2} display="flex" justifyContent="flex-end">
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleExtractSkills}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? '処理中...' : '能力を導き出す'}
      </Button>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SkillExtractor;
