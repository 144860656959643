import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import FeedIcon from "@mui/icons-material/Feed";
import GroupIcon from "@mui/icons-material/Group";
import EventIcon from "@mui/icons-material/Event";
import ChatIcon from "@mui/icons-material/Chat";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import KomebuGroupBackImage from "./KomebuGroupBackImage";
import KomebuGroupMainImage from "./KomebuGroupMainImage";
import Feeds from "./Feeds";
import GroupMembers from "./GroupMembers";
import GroupEvents from "./GroupEvents";
import StreamChatOnGroup from "./StreamChatOnGroup";
import GroupSetting from "./GroupSetting";
import GroupChatSetting from "./GroupChatSetting";
import { db, auth } from "../api/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  runTransaction,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import KomebuGroupMembers from "./KomebuGroupMembers";
import KomebuGroupEvents from "./KomebuGroupEvents";
import KomebuGroupSetting from "./KomebuGroupSetting";

const KomebuGroupDialog = ({ open, onClose, group, onGroupUpdate }) => {
  const [value, setValue] = useState("1");
  const [user, setUser] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); // 参加確認ダイアログ表示
  const [groupMembers, setGroupMembers] = useState([]); // メンバー情報のstate

  // console.log("groupmembers =>", groupMembers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // 現在のユーザー情報をFirestoreから取得
  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userDocRef);

        if (userSnap.exists()) {
          setUser(userSnap.data());

          // members配列内に自分のuidがあり、かつ isMember が true であるか確認
          const isMemberCheck = group.members?.some(
            (m) => m.uid === currentUser.uid && m.isMember === true
          );

          setIsMember(isMemberCheck);
        }
      }
    };

    fetchUser();
  }, [group]);

  useEffect(() => {
    if (!group?.id) return;

    const groupRef = doc(db, "komebuGroups", group.id);
    const unsubscribe = onSnapshot(groupRef, (docSnap) => {
      if (docSnap.exists()) {
        onGroupUpdate(docSnap.data());
      }
    });

    return () => unsubscribe();
  }, [group?.id]);

  const handleJoinGroup = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) return;

    try {
      const groupRef = doc(db, "komebuGroups", group.id);
      const userRef = doc(db, "users", currentUser.uid);

      await runTransaction(db, async (transaction) => {
        const groupDoc = await transaction.get(groupRef);
        const userDoc = await transaction.get(userRef);

        if (!groupDoc.exists()) {
          throw new Error("グループが存在しません。");
        }

        const currentMembers = groupDoc.data().members || [];

        // すでに参加しているかをチェック
        const isAlreadyMember = currentMembers.some(
          (member) => member.uid === currentUser.uid && member.isMember
        );

        if (isAlreadyMember) {
          throw new Error("すでにこのグループに参加しています。");
        }

        // Firestoreサーバータイムスタンプをトランザクションで取得
        const timestamp = new Date();

        // グループにユーザーを追加し、membersCountを更新
        transaction.update(groupRef, {
          members: [
            ...currentMembers,
            {
              uid: currentUser.uid,
              joinedAt: timestamp, // タイムスタンプを利用
              role: "member",
              isMember: true,
            },
          ],
          membersCount: currentMembers.length + 1,
        });

        const joinedGroups = userDoc.data().joinedGroups || [];

        // ユーザーのjoinedGroupsを更新
        transaction.update(userRef, {
          joinedGroups: [
            ...joinedGroups,
            {
              groupId: group.id,
              groupName: group.groupName,
              joinedAt: timestamp, // タイムスタンプを利用
              isMember: true,
            },
          ],
        });
      });

      setIsMember(true); // 状態を更新
      setShowConfirm(false); // ダイアログを閉じる
    } catch (err) {
      console.error("参加エラー:", err.message);
    }
  };

  const handleLeaveGroup = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) return;

    try {
      const groupRef = doc(db, "komebuGroups", group.id);
      const userRef = doc(db, "users", currentUser.uid);

      await runTransaction(db, async (transaction) => {
        const groupDoc = await transaction.get(groupRef);
        const userDoc = await transaction.get(userRef);

        if (!groupDoc.exists() || !userDoc.exists()) {
          throw new Error("データが存在しません。");
        }

        const currentMembers = groupDoc.data().members || [];

        // 自分を `members` 配列から削除
        const updatedMembers = currentMembers.filter(
          (member) => member.uid !== currentUser.uid
        );

        // メンバー数を更新
        transaction.update(groupRef, {
          members: updatedMembers,
          membersCount: updatedMembers.length,
        });

        const joinedGroups = userDoc.data().joinedGroups || [];

        // `joinedGroups` 配列を更新 (該当グループを isMember: false に)
        const updatedGroups = joinedGroups.map((groupEntry) =>
          groupEntry.groupId === group.id
            ? { ...groupEntry, isMember: false }
            : groupEntry
        );

        transaction.update(userRef, {
          joinedGroups: updatedGroups,
        });
      });

      setIsMember(false); // UIの状態を更新
      // console.log("グループを辞めました");
    } catch (err) {
      console.error("グループを辞める処理でエラー:", err.message);
    }
  };

  useEffect(() => {
    if (!group?.id) return;
  
    const groupRef = doc(db, "komebuGroups", group.id);
    const unsubscribe = onSnapshot(groupRef, (docSnap) => {
      if (docSnap.exists()) {
        const groupData = docSnap.data();
        setGroupMembers(groupData.members || []); // groupMembers を state にセット
      }
    });
  
    return () => unsubscribe();
  }, [group?.id]);
  
  // 現在のユーザーがメンバーかどうかを確認
  useEffect(() => {
    if (!groupMembers.length || !auth.currentUser) return;
  
    const currentUser = auth.currentUser;
    const isMemberCheck = groupMembers.some(
      (m) => m.uid === currentUser.uid && m.isMember === true
    );
    setIsMember(isMemberCheck);
  }, [groupMembers]);
  
  if (!group || !user) return null;

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          width: "90%",
          height: "90vh",
          margin: "auto",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <DialogTitle
            sx={{
              p: 0,
              fontSize: "1.5rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis", // 省略表示
              maxWidth: "calc(100% - 50px)", // 最大幅を設定（Closeボタンの余白を考慮）
            }}
          >
            {group.groupName}
          </DialogTitle>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ flex: 1, overflowY: "auto", p: 2 }}>
          <Box sx={{ mb: 3 }}>
            <KomebuGroupBackImage group={group} />
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                mt: -10,
              }}
            >
              <KomebuGroupMainImage group={group} />
            </Box>
          </Box>

          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{
                wordBreak: "break-word", // 長い単語を折り返す
                overflow: "hidden",
                whiteSpace: "normal",
                maxWidth: "100%",
              }}
            >
              {group.groupName}
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              gutterBottom
              sx={{
                wordBreak: "break-word", // 長い単語を折り返す
                overflow: "hidden",
                whiteSpace: "normal",
                maxWidth: "100%",
              }}
            >
              {group.description || "説明はありません。"}
            </Typography>

            {/* 参加ボタン or グループを辞めるボタン */}
            {!isMember ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowConfirm(true)}
              >
                参加する
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                onClick={handleLeaveGroup}
                sx={{ mt: 2 }}
              >
                グループを辞める
              </Button>
            )}
          </Box>

          {/* 参加確認ダイアログ */}
          {showConfirm && (
            <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
              <DialogTitle>本当に参加しますか？</DialogTitle>
              <DialogContent>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleJoinGroup}
                  sx={{ mr: 2 }}
                >
                  はい
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setShowConfirm(false)}
                >
                  キャンセル
                </Button>
              </DialogContent>
            </Dialog>
          )}

          <Box sx={{ border: "1px solid #e0e0e0"}}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="Feed" value="1" icon={<FeedIcon />} />
                  <Tab label="Members" value="2" icon={<GroupIcon />} />
                  <Tab label="Event" value="3" icon={<EventIcon />} />
                  <Tab label="Chat" value="4" icon={<ChatIcon />} />
                  {(user?.isAdmin ||
                    group.members?.some(
                      (member) =>
                        member.uid === auth.currentUser.uid &&
                        member.role === "admin"
                    )) && (
                    <Tab label="設定" value="5" icon={<SettingsIcon />} />
                  )}
                  {/* <Tab
                    label="チャット設定"
                    value="6"
                    icon={<ChatBubbleIcon />}
                  /> */}
                </TabList>
              </Box>

              <TabPanel value="1">
                <Feeds target="komebu" id={group.id} />
              </TabPanel>

              <TabPanel value="2">
                <KomebuGroupMembers members={groupMembers} />
              </TabPanel>

              <TabPanel value="3">
                <KomebuGroupEvents group={group} isMember={isMember} />
              </TabPanel>

              <TabPanel value="4">
                <StreamChatOnGroup user={user} group={group} />
              </TabPanel>

              {/* 設定タブ */}
              {(user?.isAdmin ||
                group.members?.some(
                  (member) =>
                    member.uid === auth.currentUser.uid &&
                    member.role === "admin"
                )) && (
                <TabPanel value="5">
                  <TabPanel value="5">
                    <KomebuGroupSetting
                      group={group}
                      onGroupUpdate={onGroupUpdate}
                    />
                  </TabPanel>
                  {/* <GroupSetting group={group} /> */}
                </TabPanel>
              )}

              {/* <TabPanel value="6">
                <GroupChatSetting group={group} user={user} />
              </TabPanel> */}
            </TabContext>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default KomebuGroupDialog;
