import React, { memo, useState } from "react";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import ModalForm from "./ModalForm";
import DisplayGroupEvents from "./DisplayGroupEvents";
import { useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../api/firebase";

const BaseEvents = memo(({ isLeader, isMember }) => {
  const { id } = useParams();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm("makeBaseEvent");
    setValue("1");
  };
  // グループ情報取得
  const [base, setBase] = useState("");
  useEffect(() => {
    const getBaseInfo = async () => {
      getDoc(doc(db, "base", id)).then((doc) => {
        setBase({ id: doc.id, ...doc.data() });
      });
    };
    getBaseInfo();
  }, [id]);

  //   グループが持っている publishイベント取得
  const [allEvents, setAllEvents] = useState([]);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "event"),
        where("target", "==", "base"),
        where("targetId", "==", id),
        where("publish", "==", true)
      );
      const arr = [];
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        arr.sort((a, b) => {
          if (a.publishDate > b.publishDate) return 1;
          if (a.publishDate < b.publishDate) return -1;
        });
        setAllEvents(arr);
      });
    };

    init();
  }, [id]);

  //   グループが持っている 未承認イベント取得
  const [allNotPublishEvents, setAllNotPublishEvents] = useState([]);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "event"),
        where("target", "==", "base"),
        where("targetId", "==", id),
        where("publish", "==", false)
      );
      const arr = [];
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        arr.sort((a, b) => {
          if (a.publishDate > b.publishDate) return 1;
          if (a.publishDate < b.publishDate) return -1;
        });
        setAllNotPublishEvents(arr);
      });
    };

    init();
  }, [id]);

  // 未開催イベント　publishEnd(掲載終了)以前のevents[]を取得
  const [activeEvents, setActiveEvents] = useState([]);
  useEffect(() => {
    if (!allEvents) return;
    const setActiveEvent = async () => {
      const publishEvents = allEvents.filter(
        (c) => c.publishDate.toDate() >= new Date()
      );
      setActiveEvents(publishEvents);
    };
    setActiveEvent();
  }, [allEvents]);

  // 終了イベント　publishEnd(掲載終了)後のevents[]を取得
  const [finishedEvents, setfinishedEvents] = useState([]);
  useEffect(() => {
    if (!allEvents) return;
    const setFinishedEvent = async () => {
      const finishedEvents = allEvents.filter(
        (c) =>
          // console.log(new Date(c.publishDate.toDate()))
          c.publishDate.toDate() < new Date()
      );
      finishedEvents.sort((a, b) => {
        if (a.publishDate > b.publishDate) return -1;
        if (a.publishDate < b.publishDate) return 1;
      });

      setfinishedEvents(finishedEvents);
    };
    setFinishedEvent();
  }, [allEvents]);

  //   console.log("activeEvents =>", activeEvents);

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} />

      <div className="w-[calc(100%)]">
        <TabContext value={value}>
          {/* 左リスト */}

          <Box sx={{ maxWidth: { xs: 325, sm: 1080 } }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="未開催イベント" value="1" style={{ p: 0, m: 0 }} />
              <Tab label="承認待ちイベント" value="2" />
              <Tab label="終了済イベント" value="3" />
              {(isLeader || (isMember && base?.doMakeEventsEveryOne)) && (
                <Tab label="＋イベント作成" onClick={handleModalOpen} />
              )}

              {/* {isFounder && <Tab label="＋イベント作成" onClick={handleModalOpen} />}
              {group?.doMakeEventsEveryOne && (
                <Tab label="＋イベント作成" onClick={handleModalOpen} />
              )} */}
            </TabList>
          </Box>

          {/* 未開催イベント */}
          <TabPanel sx={{ width: "100%", padding: "0" }} value="1">
            <DisplayGroupEvents targetEvents={activeEvents} />
          </TabPanel>
          {/* 承認待ちイベント */}
          <TabPanel sx={{ width: "100%", padding: "0" }} value="2">
            <DisplayGroupEvents targetEvents={allNotPublishEvents} />
          </TabPanel>
          {/* 終了済イベント */}
          <TabPanel sx={{ width: "100%", padding: "0" }} value="3">
            <DisplayGroupEvents targetEvents={finishedEvents} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
});

export default BaseEvents;
