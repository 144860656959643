import React, { useState, useEffect } from "react";
import CreateHeaderMenu from "./CreateHeaderMenu";
import Notifications from "./Notifications";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import NotificationsInMessage from "./NotificationsInMessage";
import QuestionForAdminIcon from "./QuestionForAdminIcon";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ApprovalPendingIcon from "./ApprovalPendingIcon";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ForestIcon from "@mui/icons-material/Forest";
import RiceBowlIcon from "@mui/icons-material/RiceBowl";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  doc,
  updateDoc,
  onSnapshot,
  query,
  collection,
  limit,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useAuth } from "../context/auth";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { useNavigate } from "react-router-dom";

const HeaderMenus = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState([]);

  //    遷移系
  const navigate = useNavigate(9);
  // 共通のナビゲーション関数
  const handleNavigate = (path, checkCurrentPath = false) => {
    if (!checkCurrentPath || window.location.pathname !== path) {
      navigate(path);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  // 各ページへのハンドラー
  const handleToHome = () => handleNavigate("/home", true);
  const handleToProfile = () => handleNavigate("/profile");
  const handleToAdminSetting = () => handleNavigate("/adminsetting");
  const handleToManuals = () => handleNavigate("/manuals");
  const handleToMap = () => handleNavigate("/map");
  const handleToChat = () => handleNavigate("/chat");
  const handleToMovie = () => handleNavigate("/movies");
  const handleToDdp = () => handleNavigate("/ddps");
  const handleToMyPage = () =>
    handleNavigate(`/mypage/${auth.currentUser.uid}`);
  const handleToEvent = () => handleNavigate("/events");
  const handleToGroup = () => handleNavigate("/groups");
  const handleToBases = () => handleNavigate("/bases");
  const handleToRelease = () => handleNavigate("/release");
  const handleToKomebu = () => handleNavigate("/komebu"); // 実際のルートに置き換えてください

  useEffect(() => {
    const getUser = async () => {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        setUserInfo(doc.data());
      });
    };
    user && getUser();
  }, [user]);

  return (
    <>
      <CreateHeaderMenu
        text="動画"
        icon={LiveTvIcon}
        onclick={handleToMovie}
        complete
      />
      <CreateHeaderMenu
        text="DDP"
        icon={VolunteerActivismIcon}
        onclick={handleToDdp}
        complete
      />
      <CreateHeaderMenu
        text="マップ"
        icon={LocationOnIcon}
        onclick={handleToMap}
        complete
      />
      {/* <CreateHeaderMenu text="チャット" icon={MessageIcon} onclick={handleToChat}  complete/> */}
      {userInfo?.isVillager && (
        <CreateHeaderMenu
          text="拠点"
          icon={ForestIcon}
          onclick={handleToBases}
          complete
        />
      )}
      {userInfo?.isVillager && (
        <CreateHeaderMenu
          text="グループ"
          icon={GroupsIcon}
          onclick={handleToGroup}
          complete
        />
      )}
      {/* <CreateHeaderMenu
          text="マイページ"
          icon={PersonIcon}
          onclick={displayConstruction}
        /> */}
      <CreateHeaderMenu
        text="イベント"
        icon={EventIcon}
        onclick={handleToEvent}
        complete
      />
      {/* <CreateHeaderMenu
        text="リリース"
        icon={ViewTimelineIcon}
        onclick={handleToRelease}
        complete
      /> */}
      {/* 管理者のみ表示される「コメ部」メニュー */}
        <CreateHeaderMenu
          text="コメ部"
          icon={RiceBowlIcon} // 必要に応じて適切なアイコンに変更してください
          onclick={handleToKomebu}
          complete
        />
      {userInfo?.isVillager && window.location.href.indexOf("chat") === -1 && (
        <NotificationsInMessage />
      )}

      {/* {window.location.href.indexOf("chat") === -1 && (
          <NotificationsInMessage />
        )} */}
      <Notifications />
      {/* <CreateHeaderMenu text="お知らせ" icon={NotificationsActiveIcon} onclick={displayConstruction} /> */}
    </>
  );
};

export default HeaderMenus;
