import React, { useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import RiceBowlIcon from "@mui/icons-material/RiceBowl";
import GroupIcon from "@mui/icons-material/Group"; // 代替アイコン
import { useNavigate, useLocation } from "react-router-dom";

const BottomNavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  // URLパスに応じてBottomNavigationの選択状態を更新
  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setValue(0);
        break;
      case "/komebu":
        setValue(1);
        break;
      case "/mastermind":
        setValue(2);
        break;
      default:
        setValue(-1); // 非対応パスの場合は選択なし
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/home");
        break;
      case 1:
        navigate("/komebu");
        break;
      case 2:
        navigate("/mastermind");
        break;
      default:
        break;
    }
  };

  return (
    <div className="fixed bottom-0 w-full md:hidden">
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="コメ部" icon={<RiceBowlIcon />} />
        <BottomNavigationAction
          label={
            <span style={{ whiteSpace: "pre-line", lineHeight: "1" }}>
              マスター{"\n"}マインド
            </span>
          }
          icon={<GroupIcon />}
        />
      </BottomNavigation>
    </div>
  );
};

export default BottomNavigationBar;
