import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  Chip,
  Divider,
  CircularProgress,
} from "@mui/material";
import { db, auth } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import DirectMessage from "./DirectMessage";

// 相対日付を表示する関数
const formatRelativeDate = (date) => {
  const now = new Date();
  const difference = now.getTime() - date.getTime();
  const daysDifference = Math.floor(difference / (1000 * 3600 * 24));

  if (daysDifference === 0) {
    return "今日";
  } else if (daysDifference === 1) {
    return "昨日";
  } else if (daysDifference < 7) {
    return `${daysDifference}日前`;
  } else {
    return date.toLocaleDateString(); // 通常の日付フォーマット
  }
};

// メンバー情報を表示するカードコンポーネント
const MemberCard = ({ member }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (!member || !member.uid) return; // 無効なデータの場合は終了
        const userRef = doc(db, "users", member.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserInfo(userSnap.data());
        }
      } catch (err) {
        console.error("ユーザー情報取得エラー:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [member]);

  // Firestore Timestamp型をDate型に変換
  const parseJoinedAt = () => {
    if (member?.joinedAt?.toDate) {
      return formatRelativeDate(member.joinedAt.toDate());
    }
    return "不明";
  };

  if (!member || !member.uid) {
    return (
      <Card
        elevation={3}
        sx={{
          borderRadius: 3,
          textAlign: "center",
          p: 2,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          無効なメンバー情報です
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: 3,
        textAlign: "center",
        transition: "transform 0.3s ease-in-out",
        "&:hover": { transform: "scale(1.05)" },
      }}
    >
      <CardContent>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Avatar
              src={userInfo?.avatar || "/default-avatar.png"}
              sx={{ width: 64, height: 64, margin: "0 auto", mb: 1 }}
            />
            <Typography variant="h6" fontWeight="bold">
              {userInfo
                ? `${userInfo.lastName || ""} ${userInfo.firstName || ""}`
                : "不明なユーザー"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              参加日: {parseJoinedAt()}
            </Typography>
            <Chip
              label={member.role || "メンバー"}
              color="primary"
              size="small"
              sx={{ mt: 1, fontWeight: "bold" }}
            />
                    {/* 個人チャットを開く */}
        {member?.uid !== auth.currentUser.uid && (
          <DirectMessage memberId={member?.uid} />
        )}

          </>
        )}
      </CardContent>
    </Card>
  );
};


// グループメンバー一覧のコンポーネント
const KomebuGroupMembers = ({ members = [] }) => {
  const validMembers = members.filter((member) => member && member.uid);

  return (
    <Box sx={{ p: 3, backgroundColor: "#f9fafc", borderRadius: 4 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        グループメンバー
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {validMembers.length > 0 ? (
        <Grid container spacing={3}>
          {validMembers.map((member, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <MemberCard member={member} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography color="textSecondary">まだメンバーはいません。</Typography>
      )}
    </Box>
  );
};

export default KomebuGroupMembers;
