import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect } from "react";
import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth } from "../api/firebase";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import ModalForm from "./ModalForm";

export default function DotClickMenuAtPost({ post }) {
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (post?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    post && init();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ModalFormで使用
  const [openModal, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  const handleToPrivate = () => {};

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={openModal}
        requestId={requestId}
        target={post}
      />

      <div>
        <MoreHorizIcon
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        ></MoreHorizIcon>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleEdit("feedPrivate", post?.id)}>
            <div className="flex flex-col space-y-0">
              <div>非公開にする</div>
              <div className="text-xs text-gray-400">
                Feedから非公開にします。
              </div>
            </div>
          </MenuItem>
          <MenuItem onClick={() => handleEdit("editPostMessage", post?.id)}>
            <div className="flex flex-col space-y-0">
              <div>編集する</div>
              <div className="text-xs text-gray-400">
                投稿テキストを編集します。
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
