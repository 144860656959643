import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  DialogActions,
  FormHelperText,
} from "@mui/material";
import { db } from "../api/firebase";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const GroupCreationDialog = ({ onGroupCreated, onClose, user }) => {
  const [groupName, setGroupName] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // データ送信
  const handleSubmit = async () => {
    if (!groupName || !prefecture || !description) {
      setErrorMessage("すべての項目を入力してください。");
      return;
    }

    try {
      // ドキュメントを作成し、そのIDを取得
      const docRef = await addDoc(collection(db, "komebuGroups"), {
        groupName,
        prefecture,
        description,
        createdAt: serverTimestamp(),
        user,
      });

      // ドキュメントのIDをフィールドとして追加
      await updateDoc(doc(db, "komebuGroups", docRef.id), {
        id: docRef.id,
      });

      setErrorMessage(""); // エラーメッセージをクリア
      onGroupCreated(true); // グループ作成成功時の処理
    } catch (error) {
      console.error("グループの作成に失敗しました:", error);
      setErrorMessage("グループの作成に失敗しました。再試行してください。");
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h6" gutterBottom>
        グループ作成
      </Typography>

      <TextField
        fullWidth
        label="グループ名"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        select
        label="都道府県"
        value={prefecture}
        onChange={(e) => setPrefecture(e.target.value)}
        margin="normal"
        required
      >
        {/* 北海道・東北 */}
        <MenuItem disabled>北海道・東北</MenuItem>
        {[
          "北海道",
          "青森県",
          "岩手県",
          "宮城県",
          "秋田県",
          "山形県",
          "福島県",
        ].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 関東 */}
        <MenuItem disabled>関東</MenuItem>
        {[
          "茨城県",
          "栃木県",
          "群馬県",
          "埼玉県",
          "千葉県",
          "東京都",
          "神奈川県",
        ].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 中部 */}
        <MenuItem disabled>中部</MenuItem>
        {[
          "新潟県",
          "富山県",
          "石川県",
          "福井県",
          "山梨県",
          "長野県",
          "岐阜県",
          "静岡県",
          "愛知県",
        ].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 近畿 */}
        <MenuItem disabled>近畿</MenuItem>
        {[
          "三重県",
          "滋賀県",
          "京都府",
          "大阪府",
          "兵庫県",
          "奈良県",
          "和歌山県",
        ].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 中国 */}
        <MenuItem disabled>中国</MenuItem>
        {["鳥取県", "島根県", "岡山県", "広島県", "山口県"].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 四国 */}
        <MenuItem disabled>四国</MenuItem>
        {["徳島県", "香川県", "愛媛県", "高知県"].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}

        {/* 九州・沖縄 */}
        <MenuItem disabled>九州・沖縄</MenuItem>
        {[
          "福岡県",
          "佐賀県",
          "長崎県",
          "熊本県",
          "大分県",
          "宮崎県",
          "鹿児島県",
          "沖縄県",
        ].map((pref) => (
          <MenuItem key={pref} value={pref}>
            {pref}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        label="グループの説明"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
        required
      />

      {/* エラーメッセージ表示 */}
      {errorMessage && (
        <FormHelperText error style={{ marginBottom: "1rem" }}>
          {errorMessage}
        </FormHelperText>
      )}

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          キャンセル
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          作成する
        </Button>
      </DialogActions>
    </Box>
  );
};

export default GroupCreationDialog;
