import React, { useState } from "react";
import {
  Box,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const KomebuGroupSetting = ({ group, onGroupUpdate }) => {
  const [value, setValue] = useState("1");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogField, setDialogField] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (field) => {
    setDialogField(field);
    setFieldValue(group[field] || "");
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogField("");
    setFieldValue("");
  };

  const handleSave = async () => {
    if (!dialogField) return;
    try {
      const groupRef = doc(db, "komebuGroups", group.id);
      await updateDoc(groupRef, { [dialogField]: fieldValue });
      setSnackbarMessage("更新が成功しました。");
      setSnackbarOpen(true);

      // 親コンポーネントに更新を通知
      onGroupUpdate({ ...group, [dialogField]: fieldValue });
    } catch (error) {
      console.error("更新エラー:", error);
      setSnackbarMessage("更新に失敗しました。");
      setSnackbarOpen(true);
    } finally {
      handleDialogClose();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };



  
  return (
    <Box sx={{ width: "100%", my: 2 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="基本情報" value="1" />
          </TabList>
        </Box>

        <TabPanel value="1">
          {/* グループ名 */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ flex: 1, fontWeight: "bold", minWidth: "100px" }}>
              グループ名
            </Typography>
            <Typography
              sx={{
                flex: 3,
                wordBreak: "break-word", // 長い単語を折り返す
                whiteSpace: "normal", // 折り返しを有効にする
                overflow: "hidden",
              }}
            >
              {group?.groupName || "未設定"}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => handleEdit("groupName")}
            >
              編集
            </Button>
          </Box>

          {/* 説明 */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ flex: 1, fontWeight: "bold", minWidth: "100px" }}>
              説明
            </Typography>
            <Typography
              sx={{
                flex: 3,
                wordBreak: "break-word", // 長い単語を折り返す
                whiteSpace: "normal", // 折り返しを有効にする
                overflow: "hidden",
              }}
            >
              {group?.description || "未設定"}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => handleEdit("description")}
            >
              編集
            </Button>
          </Box>
        </TabPanel>
      </TabContext>

      {/* 編集用ダイアログ */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogField === "groupName" ? "グループ名" : "説明"}を編集
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline={dialogField === "description"}
            rows={dialogField === "description" ? 4 : 1}
            value={fieldValue}
            onChange={(e) => setFieldValue(e.target.value)}
            placeholder={`新しい${
              dialogField === "groupName" ? "グループ名" : "説明"
            }を入力してください`}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes("成功") ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default KomebuGroupSetting;


