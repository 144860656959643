import React, { useEffect, useState } from "react";

// MaterialUI
import { Avatar, Button, MenuList, TextareaAutosize } from "@mui/material";
import PermMediaIcon from "@mui/icons-material/PermMedia";
// firebase
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import { useParams } from "react-router-dom";

// redux - use
import { useNotification } from "../redux/useNotification";

// firebase
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { textToLink } from "./textToLink";
import InputMediaOnFeed from "./InputMediaOnFeed";

import { v4 as uuuidv4 } from "uuid";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";


const resizeFileTo1MB = async (file) => {
  const maxSizeInBytes = 1024 * 1024; // 1MB

  if (file.size <= maxSizeInBytes) {
    return file;
  }

  const image = new Image();
  image.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const maxWidth = 1024; // 画像の幅を最大1024pxに制限

      let newWidth = image.width;
      let newHeight = image.height;
      if (newWidth > maxWidth) {
        newHeight = (maxWidth / newWidth) * newHeight;
        newWidth = maxWidth;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          resolve(new File([blob], file.name, { type: file.type }));
        }
      }, file.type, 1); // 画質設定（1が最高品質）
    };
  });
};


const AddPost = ({ setOpen, target }) => {
  const { id } = useParams();
  const { displayNotification } = useNotification();
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const pathSegments = urlObject.pathname.split("/");
  const parameter = pathSegments[1];

  // 入力データコンテナ
  const [data, setData] = useState({
    message: "",
    error: "",
    loading: false,
  });
  const { message, error, loading } = data;

  const [photos, setPhotos] = useState([]);

  console.log("parameter =>", parameter);
  console.log("id =>",id)
  // console.log("value =>", new Date(value));
  // データ変更処理
  const handleChange = async (e) => {
    if (e.target.name === "message") {
      setData({
        ...data,
        message: textToLink(e.target.value),
      });
      return;
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("data =>", data);

  // console.log("parameter =>", parameter);
  // 処理
  const doRegist = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        // コレクションに追加
        const docRef = collection(db, "post");
        let docData = {
          message,
          creator: auth.currentUser.uid,
          isPublish: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        if (parameter === "group" || parameter === "base") {
          docData = {
            ...docData,
            target: parameter,
            targetId: id,
          };
        } else {
          docData = {
            ...docData,
            target: "home",
          };
        }


        // if (parameter !== "home") {
        //   docData = {
        //     ...docData,
        //     target: parameter,
        //     targetId: id,
        //   };
        // } else {
        //   docData = {
        //     ...docData,
        //     target: parameter,
        //   };
        // }

        // const docRef = await addDoc(collection(db, "post"), {
        //   message,
        //   creator: auth.currentUser.uid,
        //   isPublish: true,
        //   createdAt: serverTimestamp(),
        //   updatedAt: serverTimestamp(),
        // });
        await addDoc(docRef, docData).then((elm) => {
          if (photos) {
            photos.forEach(async (file) => {
              // console.log("file.name =>",file.file.name)
              const ImageRef = ref(
                storage,
                `feed/${elm.id} - ${file.file.name}`
              );
              const snap = await uploadBytes(ImageRef, file.file);
              const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
              await addDoc(collection(db, "postImage"), {
                url,
                path: snap.ref.fullPath,
                id: elm.id,
                isPublish: true,
                creator: auth.currentUser.uid,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                type: "post",
              });
            });
          }
        });

        // 写真アップロード処理

        displayNotification({
          message: "投稿しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
        });
        // window.location.reload();

        setOpen(false);
      } catch (err) {
        console.log(err.message);
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          component: "AddPost.js",
          user,
        });

        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };
    create();
  };

  return (
    <>
      <div>
        {/* <form onSubmit={doRegist}> */}
        <div className="flex flex-col space-y-2">
          {/* 名前とアバターエリア */}
          <div className="flex space-x-2 items-center border-t border-b p-3">
            <Avatar src={user?.avatar} />
            <div className="font-bold text-gray-500">
              {user?.lastName + user?.firstName}
            </div>
          </div>
          {/* メッセージエリア */}
          <div className="border-b p-2">
            <TextareaAutosize
              name="message"
              style={{ resize: "none", fontSize: "16px" }}
              onChange={handleChange}
              placeholder="どんな気持ち？"
              className=" p-1 w-full scrollbar-hide"
              minRows={6}
              maxRows={10}
            />
          </div>
          {/* 追加エリア */}
          <div className="flex space-x-2 p-2 border-b">
            <InputMediaOnFeed photos={photos} setPhotos={setPhotos} />
          </div>
        </div>
        <div className="my-4"></div>
        {/* 送信ボタン */}
        <div className="p-2">
          <Button
            variant="contained"
            className="w-full"
            disabled={loading || message?.length === 0}
            onClick={doRegist}
          >
            {loading ? "投稿しています..." : "投稿する"}
          </Button>

          {/* <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading || message?.length === 0}
            onClick={doRegist}
          >
            {loading ? "投稿しています..." : "投稿する"}
          </button> */}
        </div>
        {/* </form> */}
      </div>
    </>
  );
};

export default AddPost;
