import React, { useState, memo } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useInView } from "react-intersection-observer"; // Intersection Observer
import "react-horizontal-scrolling-menu/dist/styles.css";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import CircularProgress from "@mui/material/CircularProgress"; // MUIローディング
import DisplayVideo from "./DisplayVideo";

function HorizontalMenuAtMovieList({ contents }) {
  return (
    <div className="grid grid-cols-1 ">
      <div className="col-span-1">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {contents?.map((movie, idx) => (
            <LazyLoadVideo key={movie?.id} movie={movie} />
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
}

function LazyLoadVideo({ movie }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // 一度だけトリガー
    threshold: 0.1, // 10%が画面内に入ったらトリガー
  });

  return (
    <div className="w-60 p-2 m-2" ref={ref}>
      {inView ? (
        <DisplayVideo movie={movie} />
      ) : (
        <div className="flex justify-center items-center h-full">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

function LeftArrow() {
  const { scrollPrev } = React.useContext(VisibilityContext);

  return (
    <div
      className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
      onClick={() => scrollPrev()}
    >
      <div className="hidden sm:block">
        <KeyboardDoubleArrowLeftOutlinedIcon
          sx={{ fontSize: "25px", marginX: "5px" }}
        />
      </div>
    </div>
  );
}

function RightArrow() {
  const { scrollNext } = React.useContext(VisibilityContext);

  return (
    <div
      className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
      onClick={() => scrollNext()}
    >
      <div className="hidden sm:block">
        <KeyboardDoubleArrowRightOutlinedIcon
          sx={{ fontSize: "25px", marginX: "5px" }}
        />
      </div>
    </div>
  );
}

export default memo(HorizontalMenuAtMovieList);
