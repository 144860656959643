// src/components/KomebuPage.js

import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KomebuMobile from './KomebuMobile';
import KomebuDesktop from './KomebuDesktop';

const KomebuPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <KomebuMobile /> : <KomebuDesktop />;
};

export default KomebuPage;
