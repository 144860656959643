import React from "react";

import MapComponent from "./MapComponent";

const KomebuMap = () => {
  return (
    <>
      <div className="bg-white h-full">
        <div className=" h-10">komebu Map v.1.1.0</div>
        <MapComponent />
      </div>
    </>
  );
};

export default KomebuMap;
