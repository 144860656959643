import React, { useEffect, useState } from "react";
import { Chat, Channel } from "stream-chat-react";

import "stream-chat-react/dist/css/v2/index.css";

import "../streamLayout.css";

import CustomMessage from "./CustomMessage/CustomMessage";
import MessagingInput from "./MessagingInput/MessagingInputOnDDPExchange";
import MessagingThreadHeader from "./MessagingThread/MessagingThread";

import Loader1Row from "./Loader1Row";
import { ChannelInnerOnDDPExchange } from "./ChannelInner/ChannelInnerOnDDPExchange";
import { useStreamChatClient } from "../context/StreamChatContext";

export const GiphyContext = React.createContext({});

const DealChat = ({ dealId, user }) => {
  const chatClient = useStreamChatClient();
  const [giphyState, setGiphyState] = useState(false);
  const [channel, setChannel] = useState(null);
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    const initChat = async () => {
      try {
        if (chatClient) {
          const channel = chatClient.channel(
            "messaging",
            `ddpExchange-${dealId}`
          );

          await channel.watch(); // チャンネルの監視を開始
          setChannel(channel);
        }
      } catch (error) {
        console.error("チャットの初期化エラー:", error);
      }
    };

    initChat();
  }, [chatClient, dealId]);

  if (!chatClient || !chatClient.userID || !channel) {
    return <Loader1Row />;
  }

  return (
    <Chat client={chatClient} className="w-[99%] h-96">
      <div className="flex w-[99%] h-96 rounded-lg overflow-auto border box-content">
        <div className="w-[100%] h-96 rouded-r-xl">
          <Channel
            Input={MessagingInput}
            channel={channel}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}
          >
            <GiphyContext.Provider value={{ giphyState, setGiphyState }}>
              <ChannelInnerOnDDPExchange theme={theme} headerNo />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </div>
    </Chat>
  );
};

export default DealChat;
