import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db } from "../api/firebase";
import ModalForm from "./ModalForm";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import DisplayReplyPostComment from "./DisplayReplyPostComment";
import DeleteIcon from "@mui/icons-material/Delete"; // MUIのアイコンをインポート

const DisplayPostComment = ({ comment }) => {
  // 自分のコメントかどうか
  const [isMyComment, setIsMyComment] = useState(false);
  // 管理者かどうか
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (comment?.creator === auth.currentUser.uid) {
        setIsMyComment(true);
      }
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists() && userDoc.data().isAdmin) {
        setIsAdmin(true);
      }
    };
    comment && init();
  }, []);

  // コメントの返信たちを取得
  const [replies, setReplies] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      const q = query(
        collection(db, "replyPostComment"),
        where("commentId", "==", comment.id),
        where("isPublish", "==", true),
        orderBy("updatedAt", "asc")
      );
      await getDocs(q).then((docs) =>
        docs.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
      );
      setReplies(arr);
    };
    comment && init();
  }, []);


  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={comment} />

      <div className="p-5 border-t h-auto">
        <div className="flex space-x-2">
          <Avatar src={comment?.avatar} sx={{ width: 30, height: 30 }} />
          <div className="flex flex-col bg-gray-100 p-2 rounded-md">
            {/* コメント.名前 */}
            <div className="flex justify-between items-center">
              <div className="text-sm font-bold">{comment?.name}</div>
              {/* 自分のコメント削除 */}
              {isMyComment || isAdmin && (
                <div
                  className="text-xs text-blue-500 hover:font-bold hover:cursor-pointer hover:underline"
                  onClick={() => handleEdit("deleteComment")}
                >
                  削除
                </div>
              )}
            </div>
            {/* コメント.メッセージ部 */}
            <div className="text-sm font-fancy text-gray-500 break-words whitespace-pre-wrap">
              {comment?.message}
            </div>
            {/* 編集と返信 */}
            <div className="flex space-x-2 mt-5">
              {/* 時間 */}
              <div className="h-fit text-blue-500 text-xs">
                -
                {formatDistanceToNow(comment?.createdAt.toDate(), {
                  locale: ja,
                })}
                前
              </div>
              {/* 編集 */}
              {isMyComment && (
                <div
                  className="text-xs text-blue-500 hover:font-bold hover:cursor-pointer hover:underline"
                  onClick={() => handleEdit("editPostComment")}
                >
                  編集
                </div>
              )}
              {/* 返信する */}
              <div
                className="text-xs w-fit text-blue-500 hover:font-bold hover:cursor-pointer hover:underline"
                onClick={() => handleEdit("replyPostComment")}
              >
                返信する
              </div>
            </div>
          </div>
        </div>
        {/* コメントの返信たち */}
        <div className="flex flex-row items-end mt-3">
          <div className="flex flex-col">
            {replies?.map((reply) => (
              <DisplayReplyPostComment reply={reply} key={Math.random()} />
            ))}
          </div>
        </div>
      </div>

    </>
  );
};

export default DisplayPostComment;
