import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultImage.png";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNotification } from "../redux/useNotification";

const KomebuGroupBackImage = ({ group }) => {
  const { displayNotification } = useNotification();
  const [groupDoc] = useDocumentData(doc(db, "komebuGroups", group?.id));
  const [currentUserData, setCurrentUserData] = useState(null);

  // 背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const backImgRef = useRef(null);

  // ユーザー情報を取得
  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setCurrentUserData(userDocSnap.data());
        }
      }
    };
    fetchUserData();
  }, []);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);
      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `komebuGroupBackImage/${new Date().getTime()} - ${backImg.name}`
        );
        try {
          if (groupDoc?.backgroundPath) {
            await deleteObject(ref(storage, groupDoc?.backgroundPath));
          }
          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
          await updateDoc(doc(db, "komebuGroups", group?.id), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          setBackImg("");
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  const checkPicture = (e) => {
    if (e.target.files[0]?.type !== "image/jpeg") {
      displayNotification({
        message: "画像ファイルを指定してください",
        type: "error",
      });
      return;
    }
    setBackImg(e.target.files[0]);
  };

  const hasPermission = () => {
    return (
      (group?.founder?.includes(auth.currentUser.uid) ||
        currentUserData?.isAdmin)
    );
  };

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="グループバックイメージ" action="更新中" />;
      </>
    );
  }

  return (
    <div
      className={` ${
        hasPermission() && "hover:opacity-50 cursor-pointer group"
      }
      relative flex justify-center items-center w-full `}
    >
      {groupDoc?.background ? (
        <img
          src={groupDoc?.background || defaultBackImg}
          object-fit="cover"
          className="w-full h-64 sm:h-96 rounded-t-md"
          alt=""
          onClick={() => hasPermission() && backImgRef.current.click()}
        />
      ) : (
        <img
          src={defaultBackImg}
          alt="バックイメージ"
          className="w-full h-64 sm:h-96 rounded-t-md"
        />
      )}
      {hasPermission() && (
        <div
          className="absolute top-2 sm:hidden group-hover:flex items-center justify-center text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"
          onClick={() => backImgRef.current.click()}
        >
          <Camera className="" />
          <input
            ref={backImgRef}
            onChange={(e) => checkPicture(e)}
            type="file"
            accept=".jpg,.png"
            hidden
          />
        </div>
      )}
    </div>
  );
};

export default KomebuGroupBackImage;
