import React, { useEffect, useState } from "react";
import DDPetcSearch from "./DDPetcSearch";
import Logo from "./Logo";
import { MenuIcon } from "@heroicons/react/solid";
import { useAuth } from "../context/auth";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../api/firebase";

import CachedIcon from "@mui/icons-material/Cached";
import { Tooltip } from "stream-chat-react";
import CreateHeaderMenu from "./CreateHeaderMenu";

const HeaderLeftMenus = ({ open }) => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const initUser = async () => {
      getDoc(doc(db, "users", user.uid)).then((doc) => {
        setUserInfo(doc.data());
      });
    };
    user && initUser();
  }, [user?.uid]);

  const reload = () => {
    // console.log("hoge");
    window.location.reload();
  };

  return (
    <>
      <div
        className={`${
          open ? "ml-0" : "lg:ml-11"
        }  flex   items-center space-x-3 w-full  `}
      >
        <Logo />
        <CreateHeaderMenu
          text="再読込"
          icon={CachedIcon}
          onclick={reload}
          complete
        />

        {userInfo?.isVillager && <DDPetcSearch />}
      </div>
    </>
  );
};

export default HeaderLeftMenus;
