import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { db, auth } from "../api/firebase";
import DisplayGroupEvents from "./DisplayGroupEvents";

const KomebuGroupEvents = ({ group, isMember }) => { // propsからisMemberを受け取る
  const [value, setValue] = useState("1");
  const [events, setEvents] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [finishedEvents, setFinishedEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    date: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // グループIDを取得
  const groupId = group?.id;

  // グループのイベントを取得
  useEffect(() => {
    if (!groupId) return;

    const fetchEvents = async () => {
      const q = query(
        collection(db, "event"),
        where("target", "==", "group"),
        where("targetId", "==", groupId)
      );
      const eventDocs = await getDocs(q);
      const fetchedEvents = eventDocs.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEvents(fetchedEvents);
    };

    fetchEvents();
  }, [groupId]);

  // 未開催イベントと終了イベントを分類
  useEffect(() => {
    const now = new Date();
    const active = events.filter((event) => event.date.toDate() >= now);
    const finished = events.filter((event) => event.date.toDate() < now);

    setActiveEvents(active);
    setFinishedEvents(finished);
  }, [events]);

  // ダイアログ制御
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewEvent({ title: "", description: "", date: "" }); // フォームリセット
  };

  // 新しいイベントの作成
  const handleCreateEvent = async () => {
    if (!newEvent.title || !newEvent.date) {
      alert("タイトルと日付を入力してください");
      return;
    }

    try {
      await addDoc(collection(db, "event"), {
        ...newEvent,
        date: new Date(newEvent.date), // 日付を適切にフォーマット
        target: "group",
        targetId: groupId,
        createdAt: serverTimestamp(),
        creator: auth.currentUser.uid,
      });

      alert("イベントが作成されました");
      handleDialogClose();
    } catch (err) {
      console.error("イベント作成エラー:", err);
    }
  };


  return (
    <Box sx={{ width: "100%" }}>
      {/* イベント作成ボタン */}
      {isMember && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            ＋イベント作成
          </Button>
        </Box>
      )}

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="未開催イベント" value="1" />
            <Tab label="終了済イベント" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <DisplayGroupEvents targetEvents={activeEvents} />
        </TabPanel>
        <TabPanel value="2">
          <DisplayGroupEvents targetEvents={finishedEvents} />
        </TabPanel>
      </TabContext>

      {/* イベント作成ダイアログ */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>イベント作成</DialogTitle>
        <DialogContent>
          <TextField
            label="タイトル"
            fullWidth
            margin="normal"
            value={newEvent.title}
            onChange={(e) =>
              setNewEvent({ ...newEvent, title: e.target.value })
            }
          />
          <TextField
            label="説明"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={newEvent.description}
            onChange={(e) =>
              setNewEvent({ ...newEvent, description: e.target.value })
            }
          />
          <TextField
            label="日付"
            type="datetime-local"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={newEvent.date}
            onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            キャンセル
          </Button>
          <Button
            onClick={handleCreateEvent}
            color="primary"
            variant="contained"
          >
            作成
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KomebuGroupEvents;
