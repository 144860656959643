import React, { memo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  where,
  query,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import UserCard from "./UserCard";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalForm from "./ModalForm";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import Box from "@mui/material/Box";
import BaceBackImage from "./BaseBackImage";
import { TabList } from "@mui/lab";
import Loading from "./Loading";

import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

// redux - use
import { useNotification } from "../redux/useNotification";
import BaseSetting from "./BaseSetting";
import BaseMembers from "./BaseMembers";
import StreamChatOnBase from "./StreamChatOnBase";
import BaseEvents from "./BaseEvents";
import BaseUpdates from "./BaseUpdates";
import BaseMembersList from "./BaseMembersList";
import ResidentList from "./ResidentList";
import Feeds from "./Feeds";

const Base = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ情報取得
  const [user, setUser] = useState("");
  useEffect(() => {
    const getUserInfo = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    getUserInfo();
  }, []);

  // 情報取得
  const [base, setBase] = useState();
  useEffect(() => {
    getDoc(doc(db, "base", id)).then((doc) => {
      setBase({ id: doc.id, ...doc.data() });
    });
  }, [id]);

  // 拠点のメンバーを取得
  const [members, setMembers] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      getDocs(collection(db, "base", id, "members")).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setMembers(arr);
      });
    };
    init();
  }, [id]);

  // console.log("members =>",members)

  // グループの開設者かどうか
  const [isFounder, setIsFounder] = useState(false);
  // useEffect(() => {
  //   const init = async () => {
  //     const q = query(
  //       collection(db, "base", id, "members"),
  //       where("uid", "==", auth.currentUser.uid),
  //       where("isOwner", "==", true)
  //     );
  //     const docSnap = await getDocs(q);
  //     if (docSnap.size > 0) {
  //       setIsFounder(true);
  //     }
  //   };
  //   init();
  // }, []);

  // リーダーかどうか
  const [isLeader, setIsLeader] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "base", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isChief", "==", true)
      );
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        setIsLeader(true);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (user?.isAdmin) {
      setIsLeader(true);
    }
  }, [user]);

  // 拠点に参加しているかどうか
  const [isMember, setIsMember] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "base", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isMember", "==", true)
      );
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        setIsMember(true);
      }
    };
    init();
  }, []);

  // console.log("isMember =>", isMember);
  // グループに参加申請しているかどうか
  const [isApplication, setIsApplication] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "base", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isMember", "==", false),
        where("isApplication", "==", true)
      );
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        setIsApplication(true);
      }
    };
    init();
  }, []);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [resultText, setResultText] = useState(window.location.href);
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(resultText);
    displayNotification({
      message: "URLをコピーしました",
    });
  };

  // console.log("base =>", base);

  // console.log("isMemner =>",isMember)
  // console.log("isLeader =>",isLeader)
  if (!base) {
    return <Loading />;
  }
  // console.log("group.isPublish =>", group?.isPublish);
  // if (!base?.isPublish) {
  //   return <div>拠点の使用が一時停止されています。</div>;
  // }

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} requestId={id} />
      <div>
        {/* 全体へ戻るボタン */}
        <div className="absolute  mb-2" onClick={() => navigate("/bases")}>
          <div className="rounded-full p-2 border w-10">
            <ArrowBackIcon />
          </div>
        </div>

        {/* タイトルなど */}
        <div className="flex space-x-5 h-full ">
          {/* 左 */}
          <div className="grow w-9/12">
            <div className="w-full  space-y-3 ">
              {/* タイトルやイメージ、入退会ボタンなど */}
              <div className="relative flex flex-col pb-2 rounded-md border bg-white ">
                {/* 上　backImage */}
                <div className="h-3/5 ">
                  {base && <BaceBackImage base={base} isLeader={isLeader} />}
                </div>
                {/* 下　mainImageやタイトルなど */}
                <div className="flex flex-col justify-center sm:flex-row h-2/5 ">
                  {/* mainImage */}
                  {/* <div className="absolute right-0 left-0 mx-auto sm:mx-0  sm:left-5   top-32 sm:top-72 border-white border-4 rounded-md bg-white z-20 w-52 h-56 ">
                    {base && <GroupMainImage base={base} />}
                  </div> */}

                  {/* タイトルなど */}
                  <div className="flex flex-col p-4 space-y-4 justify-center items-center">
                    {/* タイトル */}
                    <div className="flex justify-center items-center space-x-2">
                      <div className="flex justify-center font-bold line-clamp-3 text-3xl">
                        {base?.title}
                      </div>
                    </div>
                    {/* 説明 */}
                    <div className="text-gray-400 text-xs line-clamp-3">
                      {base?.explain}
                    </div>

                    {/* 代表・タイプ・受け入れ・ボタン */}
                    <div className="flex space-x-2 items-center text-gray-400">
                      代表：
                      <UserCard id={base?.chief} />
                    </div>
                    {/* 入会退会ボタン */}
                    <div className="flex space-x-2">
                      {isLeader ? (
                        <div className="flex space-x-2 items-center">
                          <div className="flex border rounded-md p-2 bg-yellow-500 text-white text-xs items-center justify-center truncate whitespace-pre-wrap">
                            代表
                          </div>
                          {user?.isAdmin && (
                            <div>管理者の場合、「代表」と表示されます</div>
                          )}
                          <div>
                            <div
                              className="hover:cursor-pointer"
                              onClick={() => copyToClipboard()}
                            >
                              <Tooltip title="クリックしてURLをコピー" arrow>
                                <LinkIcon className="hover:opacity-40 duration-150" />
                              </Tooltip>
                            </div>
                          </div>{" "}
                        </div>
                      ) : isApplication ? (
                        <div className="flex border rounded-md p-2 bg-green-500 text-white text-xs items-center justify-center truncate whitespace-pre-wrap">
                          申請中
                        </div>
                      ) : isMember ? (
                        <Button
                          variant="contained"
                          onClick={() => handleEdit("quitBase", id)}
                        >
                          脱退する
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => handleEdit("addBase", id)}
                        >
                          参加する
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* コンテンツ */}
              <div className="  rounded-md w-[calc(100%)]">
                <TabContext value={value}>
                  {/* 左リスト */}

                  <Box
                    sx={{
                      maxWidth: { xs: 375, sm: 1080 },
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      <Tab label="Feed" value="1" />
                      <Tab label="基本情報" value="2" />
                      <Tab label="Members" value="3" />
                      <Tab label="Event" value="4" />
                      <Tab label="Chat" value="5" />
                      {isLeader && <Tab label="設定" value="6" />}
                      <Tab label="在住" value="7" />
                    </TabList>
                  </Box>

                  {/* Feed */}
                  <TabPanel sx={{ width: "100%" }} value="1">
                    <div className=" font-bold text-3xl">Feed</div>
                    {/* <div>制作中</div> */}
                    {isMember || user?.isAdmin ? (
                      <Feeds target="base" id={id} />
                    ) : (
                      <div>拠点のメンバーのみに公開されています。</div>
                    )}
                    {/* <ActivityFeedInGroup /> */}
                  </TabPanel>

                  {/* 基本情報 */}
                  <TabPanel sx={{ width: "100%" }} value="2">
                    <div className=" font-bold text-3xl">基本情報</div>
                    <div className="flex flex-col space-y-2">
                      {/* 住所 */}
                      <div className="flex space-x-2 items-center">
                        <div className="text-xl font-bold">住所</div>
                        {isMember || user?.isAdmin ? (
                          <div>{base?.address}</div>
                        ) : (
                          <div>拠点のメンバーのみに公開されています。</div>
                        )}
                      </div>
                      {/* 郵便番号 */}
                      <div className="flex space-x-2 items-center">
                        <div className="text-xl font-bold">郵便番号</div>
                        {isMember || user?.isAdmin ? (
                          <div>{base?.postcode}</div>
                        ) : (
                          <div>拠点のメンバーのみに公開されています。</div>
                        )}
                      </div>
                    </div>

                    {/* <ActivityFeedInGroup /> */}
                  </TabPanel>

                  {/* Members */}
                  <TabPanel sx={{ width: "100%" }} value="3">
                    <div className=" font-bold text-3xl">Members</div>
                    <BaseMembers members={members} />
                  </TabPanel>

                  {/* Events */}
                  <TabPanel sx={{ width: "100%" }} value="4">
                    <div className=" font-bold text-3xl">Events</div>
                    {/* <div>制作中</div> */}
                    <BaseEvents isLeader={isLeader} isMember={isMember} />
                  </TabPanel>

                  {/* Chat */}
                  <TabPanel
                    sx={{ width: "100%", minHeight: "720px" }}
                    value="5"
                  >
                    <div className=" font-bold text-3xl">Chat</div>
                    {isMember || isLeader ? (
                      <StreamChatOnBase
                        user={user}
                        base={base}
                        isMember={isMember}
                      />
                    ) : (
                      <div>拠点メンバーの機能です</div>
                    )}
                  </TabPanel>

                  {/* 設定 */}
                  <TabPanel sx={{ width: "100%" }} value="6">
                    <div className="grid grid-cols-1">
                      <div className=" font-bold text-3xl">設定</div>
                      <BaseSetting base={base} />
                    </div>
                    {/* <GroupSetting group={group} /> */}
                  </TabPanel>

                  {/* 在住　*/}
                  <TabPanel sx={{ width: "100%" }} value="7">
                    <div className="grid grid-cols-1">
                      <div className=" font-bold text-3xl">在住</div>
                      <ResidentList base={base} />
                      {/* <BaseSetting base={base} /> */}
                    </div>
                    {/* <GroupSetting group={group} /> */}
                  </TabPanel>
                </TabContext>
              </div>
            </div>
            {/* タブ */}

            {/* 　Feed */}
            {/* 　メッセンジャー */}
            {/* 　イベント */}
          </div>

          {/* 右 */}
          <div className="grid-0 w-3/12   hidden xl:block rounded-md">
            <div className="w-full scrollbar-hide space-y-5">
              <BaseUpdates id={id} />
              {/* <BaseMembersList id={id} /> */}
            </div>
          </div>
        </div>
        {/* メンバー一覧 */}
      </div>
    </>
  );
});

export default Base;
