import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../api/firebase";
import { collection, query, orderBy, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import KomebuGroupEditDialog from "./KomebuGroupEditDialog";
import KomebuGroupDialog from "./KomebuGroupDialog";

const regions = {
  "北海道・東北": [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
  ],
  関東: [
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
  ],
  中部北陸: [
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
  ],
  近畿: [
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
  ],
  中国: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
  四国: ["徳島県", "香川県", "愛媛県", "高知県"],
  "九州・沖縄": [
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ],
};

const KomebuGroupList = ({ user }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingGroup, setEditingGroup] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, "komebuGroups"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedGroups = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGroups(updatedGroups);
      setLoading(false);
    });

    return () => unsubscribe(); // クリーンアップ
  }, []);

  const handleGroupUpdate = (updatedGroup) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === updatedGroup.id ? { ...group, ...updatedGroup } : group
      )
    );
    setSelectedGroup((prevSelectedGroup) =>
      prevSelectedGroup?.id === updatedGroup.id
        ? { ...prevSelectedGroup, ...updatedGroup }
        : prevSelectedGroup
    );
  };

  const handleEditOpen = (group) => setEditingGroup(group);
  const handleEditClose = () => setEditingGroup(null);

  const handleDialogOpen = (group) => {
    setSelectedGroup(group);
  };

  const handleDialogClose = () => {
    setSelectedGroup(null);
  };

  const handleDeleteClick = (group) => {
    setGroupToDelete(group);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const groupRef = doc(db, "komebuGroups", groupToDelete.id);
      await deleteDoc(groupRef);
      setGroups((prevGroups) =>
        prevGroups.filter((group) => group.id !== groupToDelete.id)
      );
      setGroupToDelete(null);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("削除エラー:", error);
    }
  };

  const handleDeleteCancel = () => {
    setGroupToDelete(null);
    setDeleteDialogOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxHeight: "83vh",
        overflow: "auto",
        padding: 2,
      }}
    >
      {Object.entries(regions).map(([region, regionGroups]) => (
        <Box key={region} sx={{ marginBottom: 4 }}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            sx={{ marginBottom: 1 }}
          >
            {region}
          </Typography>
          <List>
            {groups
              .filter((group) => regions[region].includes(group.prefecture))
              .map((group) => (
                <ListItem
                  key={group.id}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    padding: "10px 16px",
                    cursor: "pointer",
                    transition: "background-color 0.5s",
                    "&:hover": {
                      backgroundColor: "#f0f8ff",
                    },
                  }}
                  onClick={() => handleDialogOpen(group)}
                >
                  <ListItemIcon>
                    {group.mainImage ? (
                      <Avatar
                        src={group.mainImage}
                        alt={group.groupName}
                        sx={{ width: 40, height: 40 }} // サイズ調整
                      />
                    ) : (
                      <GroupIcon />
                    )}
                  </ListItemIcon>

                  <ListItemText
                    primary={group.groupName}
                    secondary={
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Chip
                          label={group.prefecture}
                          sx={{
                            backgroundColor: "#e0f7fa",
                            color: "#00796b",
                            borderRadius: "16px",
                            fontWeight: "bold",
                          }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          参加人数: {group.membersCount || 0}人
                        </Typography>
                      </Box>
                    }
                  />

                  {user?.isAdmin && (
                    <>
                      {/* <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditOpen(group);
                        }}
                      >
                        <EditIcon />
                      </IconButton> */}
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(group);
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
          </List>
        </Box>
      ))}

      {editingGroup && (
        <KomebuGroupEditDialog
          open={!!editingGroup}
          onClose={handleEditClose}
          group={editingGroup}
        />
      )}

      {selectedGroup && (
        <KomebuGroupDialog
          open={!!selectedGroup}
          onClose={handleDialogClose}
          group={selectedGroup}
          onGroupUpdate={handleGroupUpdate}
        />
      )}

      {/* 削除確認ダイアログ */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>グループを削除</DialogTitle>
        <DialogContent>
          <Typography>本当にこのグループを削除しますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            キャンセル
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
          >
            はい、削除します
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default KomebuGroupList;


