import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import DrawerListItem from "./DrawerListItem";
import ChatIcon from "@mui/icons-material/Chat";
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from "react-router-dom";
import { getDoc, doc, collection, addDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useNotification } from "../redux/useNotification";

const DrawerList = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  // エラーをFirestoreに保存する関数
  const logErrorToFirestore = async (error, userInfo) => {
    try {
      await addDoc(collection(db, "errors"), {
        error: {
          message: error.message,
          stack: error.stack,
        },
        user: {
          id: userInfo?.id || null,
          email: userInfo?.email || null,
        },
        timestamp: new Date().toISOString(),
      });
    } catch (e) {
      console.error("Error logging to Firestore: ", e);
    }
  };

  // 初期ユーザー情報を取得する関数
  useEffect(() => {
    const getInitUser = async () => {
      try {
        const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (docSnap.exists()) {
          setUserInfo({ id: docSnap.id, ...docSnap.data() });
        } else {
          throw new Error("User document does not exist");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        logErrorToFirestore(error, userInfo);
        setUserInfo(null); // Fallback
      }
    };
    getInitUser();
  }, []);

  // ウィンドウリサイズ時の処理
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { displayNotification } = useNotification();

  // 各アイテムのクリック処理
  const handleToChat = () => {
    navigate("/chat");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleDrawerMenu = () => {
    setOpen(!open);
    console.log("Drawer toggled");
  };

  // ドロワーリスト
  const drawerList = [
    { text: "チャット", icon: <ChatIcon />, onClick: handleToChat },
    { text: "近隣の村民さん", icon: <GroupsIcon />, onClick: handleDrawerMenu },
  ];

  // リストフィルタリング（デスクトップとモバイルで異なるリストを表示）
  const filteredDrawerList = isDesktop
    ? drawerList.filter(item => item.text !== "近隣の村民さん")
    : drawerList;

  // ユーザー情報が正しくロードされていない場合は描画しない
  if (!userInfo?.isVillager) return null;

  return (
    <List>
      {filteredDrawerList.map((arr, index) => (
        <DrawerListItem
          key={index}
          text={arr.text}
          icon={arr.icon}
          open={open}
          onClick={arr.onClick}
          setOpen={setOpen}
        />
      ))}
    </List>
  );
};

export default DrawerList;
