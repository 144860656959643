import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { auth, db } from "../api/firebase";
import DisplayGroupMember from "./DisplayGroupMember";
import GetUserInfo from "./GetUserInfo";
import DisplayBaseMember from "./DisplayBaseMember";

const BaseMembers = ({ members }) => {
  const { id } = useParams();

  const [base] = useDocumentData(doc(db, "base", id));

  const [user, setUser] = useState("");
  useEffect(() => {
    const init = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((elm) =>
        setUser({ id: elm.id, ...elm.data() })
      );
    };
    init();
  }, []);

  // console.log("members =>", members);

  // 代表者抜き出し
  const [founder, setFounder] = useState([]);
  useEffect(() => {
    const init = async () => {
      const result = members.find((u) => u.isChief === true);
      if (result) {
        setFounder(result);
      } else {
        console.log("代表者不在");
      }
    };
    init();
  }, [members]);

  const [leader, setLeader] = useState("");
  useEffect(() => {
    const initSetLeader = async () => {
      getDoc(doc(db, "users", base?.chief)).then((docRef) => {
        setLeader({ id: docRef.uid, ...docRef.data() });
      });
    };

    base?.chief && initSetLeader();
  }, [base]);

// メンバー抜き出し
const [ments, setMents] = useState([]);
useEffect(() => {
  const init = async () => {
    // isOwner, isApplication, isMember の条件を満たすメンバーをフィルタリング
    const filteredMembers = members.filter(
      (u) =>
        u.isOwner === false &&
        u.isApplication === false &&
        u.isMember === true
    );

    // usersコレクションを参照してisVillagerがfalseのユーザーを弾く
    const filteredMents = await Promise.all(
      filteredMembers.map(async (member) => {
        const userDoc = await getDoc(doc(db, "users", member.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // isVillagerがtrueのユーザーのみ残す
          return userData.isVillager ? { ...member, ...userData } : null;
        }
        return null; // ドキュメントが存在しない場合も弾く
      })
    );

    // 結果をセット（nullを除外）
    setMents(filteredMents.filter((member) => member !== null));
  };

  init();
}, [members]);


  // 申請中のメンバー抜き出し
  const [isApplies, setIsApplies] = useState("");
  useEffect(() => {
    const init = async () => {
      const result = members.filter(
        (u) =>
          u.isOwner === false &&
          u.isApplication === true &&
          u.isMember === false
      );
      if (result) {
        setIsApplies(result);
      } else {
        // console.log("メンバー不在");
      }
    };
    init();
  }, [members]);

  // console.log("isApplies =>", isApplies);

  return (
    <>
      {/* 代表 */}
      <div>
        <div className="my-8 text-xl">代表</div>
        {leader ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
            <DisplayBaseMember member={leader} />
          </div>
        ) : (
          <div>代表者は不在です</div>
        )}
      </div>

      {/* メンバー */}
      <div>
        <div className="my-8 text-xl">メンバー</div>
        {ments?.length === 0 ? (
          <div>メンバーはまだいません</div>
        ) : (
          <div
            className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8"
            key={Math.random()}
          >
            {ments?.map((member) => (
              <DisplayBaseMember member={member} />
            ))}
          </div>
        )}
      </div>

      {/* 代表だけが見える申請状況 */}
      {(base?.chief === auth.currentUser.uid || user?.isAdmin) && (
        <div>
          <div className="my-8 text-xl">申請中</div>
          {isApplies?.length === 0 ? (
            <div>申請中のメンバーはいません</div>
          ) : (
            <div
              className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
              key={Math.random()}
            >
              {isApplies?.map((member) => (
                <DisplayBaseMember member={member} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BaseMembers;
