import React, { memo, useState } from "react";
import DisplayTerms from "./DisplayTerms";
import HeaderContainerMixIN from "./HeaderContainerMixIN";
import BottomNavigationBar from "./BottomNavigationBar";

const Entrance = ({ outlet }) => {
  const [navigationValue, setNavigationValue] = useState(0);

  const handleNavigationChange = (event, newValue) => {
    setNavigationValue(newValue);
    // 必要に応じてページ遷移や他のアクションを追加
  };

  return (
    <>
      <DisplayTerms />
      <HeaderContainerMixIN outlet={outlet} />
      {/* <BottomNavigationBar value={navigationValue} onChange={handleNavigationChange} /> */}
    </>
  );
};

export default memo(Entrance);
