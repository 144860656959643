import React, { useState, useEffect, useRef } from "react";
import {
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import PicLoaderW40 from "../components/PicLoaderW40";
import Camera from "../data/svg/Camera";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";
import BackImage from "../data/img/defaultImage.png";
// redux - use
import { useNotification } from "../redux/useNotification";

const KomebuGroupMainImage = ({ group }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [groupDoc] = useDocumentData(doc(db, "komebuGroups", group?.id));
  const [currentUserData, setCurrentUserData] = useState(null);

  // ユーザー情報を取得
  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setCurrentUserData(userDocSnap.data());
        }
      }
    };
    fetchUserData();
  }, []);

  // イメージRef
  const imgPickerRef = useRef(null);
  // イメージコンテナ
  const [img, setImg] = useState("");
  // セットコンテナ
  const [isLoading, setIsLoading] = useState("");

  // プロフィール画像変更処理
  useEffect(() => {
    if (img) {
      setIsLoading(true);
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `komebuGroupMainImage/${new Date().getTime()} - ${img.name}`
        );
        try {
          if (groupDoc?.mainImagePath) {
            await deleteObject(ref(storage, groupDoc?.mainImagePath));
          }
          const snap = await uploadBytes(imgRef, img);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
          await updateDoc(doc(db, "komebuGroups", group?.id), {
            mainImage: url,
            mainImagePath: snap.ref.fullPath,
          });
          setImg("");
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadImg();
    }
  }, [img]);

  const checkPicture = (e) => {
    if (e.target.files[0].type !== "image/jpeg") {
      displayNotification({
        message: "画像ファイルを指定してください",
        type: "error",
      });
      return;
    }
    setImg(e.target.files[0]);
  };

  // 権限チェック
  const hasPermission = () => {
    return (
      (group?.founder?.includes(auth.currentUser.uid) || currentUserData?.isAdmin)
    );
  };

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="メインイメージ" action="更新中" />;
      </>
    );
  }

  return (
    <>
      <div
        className={` ${
          hasPermission() && "hover:opacity-50 cursor-pointer group"
        }
         " relative flex justify-center items-center w-full"`}
      >
        {groupDoc?.mainImage || BackImage ? (
          <img
            src={groupDoc?.mainImage || BackImage}
            object-fit="cover"
            alt=""
            className="w-52 h-56 items-center rounded-md "
            onClick={() => hasPermission() && imgPickerRef.current.click()}
          />
        ) : (
          <PicLoaderW40 />
        )}

        {hasPermission() && (
          <div
            className="absolute inset-0 hidden group-hover:flex  items-center justify-center text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => imgPickerRef.current.click()}
          >
            <Camera className="z-30" />
            <input
              ref={imgPickerRef}
              onChange={(e) => checkPicture(e)}
              type="file"
              accept=".jpg,.png"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default KomebuGroupMainImage;
