import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";

const Map = withScriptjs(
  withGoogleMap(({ farmers, onMarkerClick }) => {
    const getMarkerIcon = (farmerStatus) => {
      switch (farmerStatus) {
        case "農家さんである":
          return "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
        case "農家ではないがお米づくりをしている":
          return "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
        case "田んぼだけがある（指導者不在）":
          return "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
        default:
          return "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      }
    };

    return (
      <div style={{ position: "relative", height: "100%" }}>
        <GoogleMap
          defaultZoom={12}
          defaultCenter={{ lat: 35.681236, lng: 139.767125 }} // 東京駅
          options={{
            gestureHandling: "greedy",
            fullscreenControl: false,
          }}
        >
          {farmers.map((farmer) => {
            const lat = parseFloat(farmer.latitude);
            const lng = parseFloat(farmer.longitude);

            if (!isNaN(lat) && !isNaN(lng)) {
              return (
                <Marker
                  key={farmer.id}
                  position={{ lat, lng }}
                  onClick={() => onMarkerClick(farmer)}
                  icon={{
                    url: getMarkerIcon(farmer.farmerStatus),
                    scaledSize: new window.google.maps.Size(32, 32), // サイズ調整
                  }}
                />
              );
            }
            return null;
          })}
        </GoogleMap>

        {/* デスクトップの凡例 */}
        {/* <div
          className="hidden sm:block absolute bottom-2 left-2 bg-white p-3 rounded-lg shadow-md text-sm z-50"
        >
          <div className="flex items-center mb-2">
            <img
              src="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
              alt="農家さんである"
              className="w-4 h-4 mr-2"
            />
            農家さんである
          </div>
          <div className="flex items-center mb-2">
            <img
              src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
              alt="農家ではないがお米づくりをしている"
              className="w-4 h-4 mr-2"
            />
            農家ではないがお米づくりをしている
          </div>
          <div className="flex items-center mb-2">
            <img
              src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
              alt="田んぼだけがある（指導者不在）"
              className="w-4 h-4 mr-2"
            />
            田んぼだけがある（指導者不在）
          </div>
          <div className="flex items-center">
            <img
              src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
              alt="その他"
              className="w-4 h-4 mr-2"
            />
            その他
          </div>
        </div> */}
      </div>
    );
  })
);

const MapComponent = () => {
  const [farmers, setFarmers] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [legendDialogOpen, setLegendDialogOpen] = useState(false); // 凡例ダイアログの状態

  useEffect(() => {
    const fetchFarmers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "farmers"));
        const fetchedFarmers = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          };
        });
        setFarmers(fetchedFarmers);
      } catch (error) {
        console.error("Error fetching farmers:", error);
      }
    };

    fetchFarmers();
  }, []);

  const handleMarkerClick = (farmer) => {
    setSelectedFarmer(farmer);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLegendDialogOpen = () => {
    setLegendDialogOpen(true);
  };

  const handleLegendDialogClose = () => {
    setLegendDialogOpen(false);
  };

  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  return (
    <>
      {/* 凡例ダイアログ */}
      <Dialog open={legendDialogOpen} onClose={handleLegendDialogClose}>
        <DialogTitle>マーカーの説明</DialogTitle>
        <DialogContent>
          <div>
            <div className="flex items-center mb-2">
              <img
                src="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                alt="農家さんである"
                className="w-4 h-4 mr-2"
              />
              農家さんである
            </div>
            <div className="flex items-center mb-2">
              <img
                src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                alt="農家ではないがお米づくりをしている"
                className="w-4 h-4 mr-2"
              />
              農家ではないがお米づくりをしている
            </div>
            <div className="flex items-center mb-2">
              <img
                src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                alt="田んぼだけがある（指導者不在）"
                className="w-4 h-4 mr-2"
              />
              田んぼだけがある（指導者不在）
            </div>
            <div className="flex items-center">
              <img
                src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                alt="その他"
                className="w-4 h-4 mr-2"
              />
              その他
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* マップ */}
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 184px)",
          position: "relative",
        }}
      >
        <Map
          farmers={farmers}
          onMarkerClick={handleMarkerClick}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />

        {/* スマホ用の凡例ボタン */}
        <Button
          onClick={handleLegendDialogOpen}
          variant="contained"
          color="primary"
          className="block sm:hidden"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000,
          }}
        >
          マーカの説明
        </Button>
      </div>

      {/* ドロワー */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 350, p: 2, position: "relative", top:26}}>
          {/* カードの閉じるボタン */}
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              position: "absolute",
              top: "56px", // 閉じるボタンの位置調整
              right: 10,
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000, // 他の要素より前面に表示
            }}
          >
            <CloseIcon />
          </IconButton>

          {selectedFarmer ? (
            <Box
              sx={{
                padding: 2,
                boxShadow: 1,
                borderRadius: 2,
                backgroundColor: "#f9f9f9",
                marginTop: "40px", // 閉じるボタン用スペース
              }}
            >
              {selectedFarmer.image && (
                <Box
                  component="img"
                  src={selectedFarmer.image}
                  alt={selectedFarmer.name}
                  sx={{
                    width: "100%",
                    height: 180,
                    objectFit: "cover",
                    borderRadius: 2,
                    marginBottom: 2,
                  }}
                />
              )}
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                {selectedFarmer.name}
              </Typography>

              {/* 改行対応とリンク対応 */}
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  overflowY: "auto", // スクロール可能にする
                  maxHeight: "200px", // 最大高さ設定
                  border: "1px solid #ddd", // スクロール領域の視覚的境界線
                  padding: "8px",
                  borderRadius: "4px",
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedFarmer.description
                    ?.replace(/\n/g, "<br>")
                    .replace(
                      /(https?:\/\/[^\s]+)/g,
                      '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:blue;">$1</a>'
                    ),
                }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                住所: {selectedFarmer.address}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                農家の状況: {selectedFarmer.farmerStatus || "情報なし"}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                品種: {selectedFarmer.variety || "情報なし"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                作業内容: {selectedFarmer.tasks || "情報なし"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/dir/?api=1&destination=${selectedFarmer.latitude},${selectedFarmer.longitude}`,
                    "_blank"
                  )
                }
              >
                Googleマップでナビ
              </Button>
            </Box>
          ) : (
            <Typography>データがありません。</Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default MapComponent;
