import React from "react";
import InputInPost from "./InputPost";
import TopPosts from "./TopPosts";
import InputBox from "./InputBox";
import TestFeed from "./TestFeed";
import { useParams } from "react-router-dom";

const Feeds = ({ target, id }) => {

  return (
    <>
      {/* <InputBox /> */}
      <InputInPost target={target} id={id}/>
      {/* <TestFeed /> */}
      {target ? <TopPosts target={target} targetId={id} /> : <TopPosts />}
    </>
  );
};

export default Feeds;
