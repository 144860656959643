import React, { useEffect, useRef, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";
import { Avatar, Button, TextareaAutosize } from "@mui/material";
import ModalForm from "./ModalForm";
import KomebuAddPostDialog from "./KomebuAddPostDialog";

const InputPost = ({ target,id }) => {
  const { displayNotification } = useNotification();
  // Komebu用ダイアログを管理するstate
  const [openKomebuDialog, setOpenKomebuDialog] = useState(false);
  //   User取得
  const [user, setUser] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((elm) =>
        setUser({ id: elm.id, ...elm.data() })
      );
    };
    init();
  }, []);

  // 入力データコンテナ
  const [data, setData] = useState({
    message: "",
    error: "",
    loading: false,
    errorMessage: "",
  });
  const { message, error, loading, errorMessage } = data;

  const handleChange = (e) => {
    // console.log("e.target.value =>", e.target.value);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const inputRef = useRef();

  // 処理 しようされていない
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        // コレクションに追加

        const postData = {
          message,
          avatar: user?.avatar,
          name: user?.lastName + user?.firstName,
          creator: auth.currentUser.uid,
          isPublish: true,
          isPermission: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        // if (group) {
        //   postData.type = "group";
        //   console.log("postData =>",postData)
        // }

        const docRef = await addDoc(collection(db, "post"), postData);

        // const docRef = await addDoc(collection(db, "post"), {
        //   message,
        //   avatar: user?.avatar,
        //   name: user?.lastName + user?.firstName,
        //   creator: auth.currentUser.uid,
        //   isPublish: true,
        //   isPermission: true,
        //   createdAt: serverTimestamp(),
        //   updatedAt: serverTimestamp(),
        // });

        inputRef.current.value = "";
        displayNotification({
          message: "投稿を完了しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
          message: "",
        });
        // window.location.reload();
      } catch (err) {
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
          errorMessage: err.message,
        });
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          component: "Feeds.js",
          creator: auth.currentUser.uid,
        });
      }
    };
    create();
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");

  const handleAddPost = (e) => {
    if (!user?.isVillager) return;
    // console.log("e =>", e);
    // console.log("e =>",e)
    // console.log("target =>",target)
    setOpen(true);
    setForm(e);
  };

  return (
    <>
      {/* KomebuAddPostDialog表示 */}
      {target === "komebu" && (
        <KomebuAddPostDialog
          open={openKomebuDialog}
          onClose={() => setOpenKomebuDialog(false)}
          targetId={id}
        />
      )}{" "}
      <ModalForm form={form} setOpen={setOpen} open={open} radius />
      <div className="border rounded-md bg-white mx-2">
        <form onSubmit={(e) => e.preventDefault()}>
          <div
            className="flex items-center space-x-2  p-3 rounded-md"
            onClick={
              () =>
                target === "komebu"
                  ? setOpenKomebuDialog(true) // Komebuの場合、KomebuAddPostDialogを表示
                  : handleAddPost("addPost") // それ以外の場合は既存処理
            }
          >
            <div>
              <Avatar
                src={user?.avatar}
                sx={{ width: "36px", height: "36px" }}
              />
            </div>
            <div className="flex items-center w-full text-gray-400 p-2 bg-back border rounded-2xl  cursor-pointer hover:bg-gray-200 duration-150  ">
              どんな気持ち？？
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InputPost;
