import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import FarmerDetailItem from "./FarmerDetailItem";

const FarmerDialog = ({ open, onClose, farmer, onUpdate = () => {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFarmer, setEditedFarmer] = useState(null); // 初期値を null に設定
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success, error, warning, info

  useEffect(() => {
    // farmer プロップが変わったときに editedFarmer を更新
    setEditedFarmer(farmer || {});
  }, [farmer]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setEditedFarmer(farmer); // 編集モード開始時に現在のデータをセット
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedFarmer({ ...editedFarmer, [name]: value });
  };

  const handleSave = async () => {
    try {
      const farmerRef = doc(db, "farmers", farmer.id);
      await updateDoc(farmerRef, editedFarmer);
      // 親コンポーネントに更新を通知
      onUpdate(editedFarmer);

      setSnackbarMessage("更新しました！");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setIsEditing(false);
    } catch (error) {
      console.error("更新中にエラーが発生しました:", error);
      setSnackbarMessage("更新に失敗しました。");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  if (!farmer) {
    return null; // farmer が null または undefined の場合はレンダリングしない
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          width: "90%",
          height: "90vh",
          margin: "auto",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <DialogTitle>
            {isEditing ? "農地情報を編集" : farmer.name || "農地情報"}
          </DialogTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* コンテンツ */}
        <DialogContent sx={{ display: "flex", height: "100%" }}>
          <Grid container sx={{ height: "100%" }}>
            {/* 左側: 農地情報（編集フォーム/表示モード） */}
            <Grid
              item
              xs={12} // スマホ以下サイズで全幅を使う
              sm={6} // デスクトップサイズでは6割幅
              sx={{
                padding: 2,
                borderRight: { sm: "2px dotted #ccc", xs: "none" }, // スマホでは右のボーダーを消す
                overflowY: "auto",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {isEditing ? "編集フォーム" : "農地詳細"}
              </Typography>
              <Box sx={{ marginBottom: 2 }}>
                {isEditing ? (
                  <>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="連絡担当者"
                      name="contactName"
                      value={editedFarmer.contactName || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="住所"
                      name="address"
                      value={editedFarmer.address || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="緯度 (Latitude)"
                      name="latitude"
                      value={editedFarmer.latitude || ""}
                      onChange={handleInputChange}
                      type="number" // 数値入力専用
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="経度 (Longitude)"
                      name="longitude"
                      value={editedFarmer.longitude || ""}
                      onChange={handleInputChange}
                      type="number" // 数値入力専用
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="名称"
                      name="name"
                      value={editedFarmer.name || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="田んぼの広さ"
                      name="size"
                      value={editedFarmer.size || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="品種"
                      name="variety"
                      value={editedFarmer.variety || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="作業内容"
                      name="tasks"
                      value={editedFarmer.tasks || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="必要なスキルや道具"
                      name="skills"
                      value={editedFarmer.skills || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="必要な人数"
                      name="requiredPeople"
                      value={editedFarmer.requiredPeople || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="紹介文"
                      name="description"
                      value={editedFarmer.description || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="電話番号"
                      name="phone"
                      value={editedFarmer.phone || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="スタンス"
                      name="stance"
                      value={editedFarmer.stance || ""}
                      onChange={handleInputChange}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="農法"
                      name="farmingMethod"
                      value={editedFarmer.farmingMethod || ""}
                      onChange={handleInputChange}
                    />
                    {/* 農家の状況セレクトメニュー */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel>農家の状況</InputLabel>
                      <Select
                        name="farmerStatus"
                        value={editedFarmer.farmerStatus || ""}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="農家さんである">
                          農家さんである
                        </MenuItem>
                        <MenuItem value="農家ではないがお米づくりをしている">
                          農家ではないがお米づくりをしている
                        </MenuItem>
                        <MenuItem value="田んぼだけがある（指導者不在）">
                          田んぼだけがある（指導者不在）
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <Box sx={{ marginBottom: 2 }}>
                      <FarmerDetailItem
                        title="連絡担当者"
                        value={farmer.contactName || "未登録"}
                      />
                      <FarmerDetailItem
                        title="住所"
                        value={farmer.address || "未登録"}
                      />
                      <FarmerDetailItem
                        title="緯度 (Latitude)"
                        value={farmer.latitude || "未登録"}
                      />
                      <FarmerDetailItem
                        title="経度 (Longitude)"
                        value={farmer.longitude || "未登録"}
                      />
                      <FarmerDetailItem
                        title="名称"
                        value={farmer.name || "未登録"}
                      />
                      <FarmerDetailItem
                        title="田んぼの広さ"
                        value={farmer.size || "未登録"}
                      />
                      <FarmerDetailItem
                        title="品種"
                        value={farmer.variety || "未登録"}
                      />
                      <FarmerDetailItem
                        title="作業内容"
                        value={farmer.tasks || "未登録"}
                      />
                      <FarmerDetailItem
                        title="必要なスキルや道具"
                        value={farmer.skills || "未登録"}
                      />
                      <FarmerDetailItem
                        title="必要な人数"
                        value={farmer.requiredPeople || "未登録"}
                      />
                      <FarmerDetailItem
                        title="紹介文"
                        value={farmer.description || "未登録"}
                      />
                      <FarmerDetailItem
                        title="電話番号"
                        value={farmer.phone || "未登録"}
                      />
                      <FarmerDetailItem
                        title="スタンス"
                        value={farmer.stance || "未登録"}
                      />
                      <FarmerDetailItem
                        title="農法"
                        value={farmer.farmingMethod || "未登録"}
                      />
                      {/* 農家の状況を追加 */}
                      <FarmerDetailItem
                        title="農家の状況"
                        value={farmer.farmerStatus || "未登録"}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>

            {/* 右側: マップ */}
            <Grid
              item
              xs={12} // スマホ時に全幅を使用
              sm={6} // デスクトップ時に6割幅を使用
              sx={{
                padding: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                農地の位置
              </Typography>
              <iframe
                title="農地位置"
                src={`https://www.google.com/maps?q=${farmer.latitude},${farmer.longitude}&hl=ja;z=14&output=embed`}
                width="100%"
                height="100%"
                style={{
                  border: "none",
                  borderRadius: "8px",
                }}
              ></iframe>
            </Grid>
          </Grid>
        </DialogContent>

        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // 6秒後に自動で閉じる
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {/* フッター */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            p: 2,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          {isEditing ? (
            <>
              <Button onClick={handleEditToggle} color="secondary">
                キャンセル
              </Button>
              <Button onClick={handleSave} color="primary" variant="contained">
                保存
              </Button>
            </>
          ) : (
            <Button
              onClick={handleEditToggle}
              color="primary"
              variant="contained"
            >
              編集
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default FarmerDialog;
