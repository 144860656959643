import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions,
  Button,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { db, auth } from '../api/firebase'; // authもインポート
import { doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import SkillExtractor from './SkillExtractor';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserExperienceForm = () => {
  const [experienceText, setExperienceText] = useState('');
  const [skills, setSkills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userInfo, setUserInfo] = useState({ name: '', email: '', bio: '' }); // 登録済みユーザー情報
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userId = auth.currentUser.uid
      const userDoc = doc(db, 'users', userId);

      try {
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
          setUserInfo(docSnap.data()); // 登録済みのユーザー情報を取得
        } else {
          console.error("ユーザー情報が見つかりませんでした。");
        }
      } catch (error) {
        console.error("ユーザー情報の取得に失敗しました:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSkillsExtracted = (extractedSkills) => {
    setSkills(extractedSkills);
    setOpenDialog(true);
  };

  const handleRegister = async () => {
    if (!experienceText.trim()) {
      setSnackbar({ open: true, message: '経歴を入力してください。', severity: 'warning' });
      return;
    }

    try {
      await addDoc(collection(db, 'users', auth.currentUser.uid, 'experiences'), {
        experienceText,
        skills,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      setSnackbar({ open: true, message: '経歴が正常に登録されました。', severity: 'success' });
      setOpenDialog(false);
      setExperienceText('');
      setSkills([]);
    } catch (error) {
      setSnackbar({ open: true, message: '登録に失敗しました。', severity: 'error' });
    }
  };

  return (
    <Box display="flex"  minHeight="100vh" bgcolor="#f5f5f5" padding={2}>
      <Box component="form" sx={{ width: '100%' }}>
        <Typography variant="h4" gutterBottom>経歴・能力登録</Typography>


        {/* 経歴入力フィールド */}
        <TextField 
          fullWidth 
          label="経歴" 
          multiline 
          rows={6} 
          value={experienceText} 
          onChange={(e) => setExperienceText(e.target.value)} 
          margin="normal" 
          placeholder="ここにあなたの経歴を入力してください..." 
        />

        <SkillExtractor 
          experienceText={experienceText}
          userName={userInfo?.lastName + userInfo?.firstName}
          onSkillsExtracted={handleSkillsExtracted}
        />

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
          <DialogTitle>能力の確認</DialogTitle>
          <DialogContent>
            <DialogContentText>{userInfo.name}さんの能力が以下に推察されます。登録しますか？</DialogContentText>
            <List>
              {skills.map((skill, index) => (
                <ListItem key={index}><ListItemText primary={skill} /></ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary">キャンセル</Button>
            <Button onClick={handleRegister} color="primary" variant="contained">登録</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default UserExperienceForm;
