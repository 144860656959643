import React, { useState } from "react";
import { Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const FarmerRegistrationForm = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [latLng, setLatLng] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSubmit = async () => {
    // 入力チェック
    if (!name || !address || !description || !latLng) {
      setSnackbarMessage("すべてのフィールドを入力してください。");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // 経度と緯度を分割
    const [latitude, longitude] = latLng.split(",").map((coord) => parseFloat(coord.trim()));

    if (isNaN(latitude) || isNaN(longitude)) {
      setSnackbarMessage("正しいフォーマットで経度と緯度を入力してください。");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // Firestore に保存
    try {
      const farmersRef = collection(db, "farmers");
      await addDoc(farmersRef, {
        name,
        address,
        description,
        latitude,
        longitude,
        createdAt: new Date(),
      });
      setSnackbarMessage("登録が成功しました！");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setName("");
      setAddress("");
      setDescription("");
      setLatLng("");
    } catch (error) {
      console.error("登録エラー:", error);
      setSnackbarMessage("登録に失敗しました。再試行してください。");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  return (
    <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField
        label="場所の名前"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      <TextField
        label="住所"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        required
      />
      <TextField
        label="説明"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        multiline
        rows={3}
        required
      />
      <TextField
        label="経度と緯度 (例: 35.38430671879635, 139.36142882753632)"
        value={latLng}
        onChange={(e) => setLatLng(e.target.value)}
        fullWidth
        required
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        登録
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FarmerRegistrationForm;
