import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Chip,
  CircularProgress,
  Avatar,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import { db } from "../api/firebase";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import KomebuGroupDialog from "./KomebuGroupDialog";

const KomebuJoinedGroups = ({ user }) => {
  const [joinedGroups, setJoinedGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (!user?.uid) return;
  
    // Firestoreのユーザードキュメントを監視
    const userRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(userRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const updatedUser = docSnapshot.data();
  
        // `isMember` が true のグループだけを対象にする
        const activeGroups = updatedUser.joinedGroups.filter(
          (group) => group.isMember === true
        );
  
        // 参加グループの情報を取得
        const groupDetails = await Promise.all(
          activeGroups.map(async (group) => {
            const groupRef = doc(db, "komebuGroups", group.groupId);
            const groupSnap = await getDoc(groupRef);
            return groupSnap.exists()
              ? { id: group.groupId, ...groupSnap.data() }
              : null;
          })
        );
  
        setJoinedGroups(groupDetails.filter((group) => group !== null));
      } else {
        console.warn("ユーザーデータが存在しません。");
      }
      setLoading(false);
    });
  
    // クリーンアップ処理
    return () => unsubscribe();
  }, [user]);
  

  const handleDialogOpen = (group) => setSelectedGroup(group);
  const handleDialogClose = () => setSelectedGroup(null);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxHeight: "83vh",
        overflow: "auto",
        padding: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        参加しているグループ
      </Typography>

      {joinedGroups.length === 0 ? (
        <Typography color="textSecondary">参加しているグループはありません。</Typography>
      ) : (
        <List>
          {joinedGroups.map((group) => (
            <ListItem
              key={group.id}
              sx={{
                borderBottom: "1px solid #e0e0e0",
                padding: "10px 16px",
                cursor: "pointer",
                transition: "background-color 0.5s",
                "&:hover": {
                  backgroundColor: "#f0f8ff",
                },
              }}
              onClick={() => handleDialogOpen(group)}
            >
                  <ListItemIcon>
                    {group.mainImage ? (
                      <Avatar
                        src={group.mainImage}
                        alt={group.groupName}
                        sx={{ width: 40, height: 40 }} // サイズ調整
                      />
                    ) : (
                      <GroupIcon />
                    )}
                  </ListItemIcon>

              <ListItemText
                primary={group.groupName}
                secondary={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Chip
                      label={group.prefecture || "不明"}
                      sx={{
                        backgroundColor: "#e0f7fa",
                        color: "#00796b",
                        borderRadius: "16px",
                        fontWeight: "bold",
                      }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      参加人数: {group.membersCount || 0}人
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      )}

      {/* グループ詳細ダイアログ */}
      {selectedGroup && (
        <KomebuGroupDialog
          open={!!selectedGroup}
          onClose={handleDialogClose}
          group={selectedGroup}
        />
      )}
    </Box>
  );
};

export default KomebuJoinedGroups;
