import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const KomebuGroupEditDialog = ({ open, onClose, group, onUpdate }) => {
  const [groupName, setGroupName] = useState(group.groupName || "");
  const [description, setDescription] = useState(group.description || "");
  const [leader, setLeader] = useState(group.leader || "");

  const handleSave = async () => {
    try {
      const groupDocRef = doc(db, "komebuGroups", group.id);
      await updateDoc(groupDocRef, { groupName, description, leader });
      onUpdate({ groupName, description, leader }); // 親コンポーネントへ変更を通知
      onClose();
    } catch (error) {
      console.error("更新に失敗しました:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>グループ編集</DialogTitle>
      <DialogContent>
        <Box component="form">
          <TextField
            label="グループ名"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="説明"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />
          <TextField
            label="代表者"
            value={leader}
            onChange={(e) => setLeader(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          キャンセル
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KomebuGroupEditDialog;
