import React from "react";
import { Typography, Box, Divider } from "@mui/material";

const FarmerDetailItem = ({ title, value }) => {
  return (
    <Box sx={{ marginBottom: 1 }}>
        <Typography variant="h5"  color="primary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">{value}</Typography>
        <Divider></Divider>
    </Box>
  );
};

export default FarmerDetailItem;
