import React, { useState } from "react";
import {
  Avatar,
  Button,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import InputMediaOnFeed from "./InputMediaOnFeed";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useNotification } from "../redux/useNotification";

const KomebuAddPostDialog = ({ open, onClose, targetId }) => {
  const { displayNotification } = useNotification();
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const [data, setData] = useState({
    message: "",
    loading: false,
    error: "",
  });
  const { message, loading } = data;

  const [photos, setPhotos] = useState([]);

// console.log("targetId =>",targetId)

  // 入力変更
  const handleChange = (e) => {
    setData({
      ...data,
      message: e.target.value,
    });
  };

  // 投稿処理
  const doRegist = async () => {
    setData({ ...data, loading: true, error: "" });

    try {
      const postData = {
        message,
        creator: auth.currentUser.uid,
        target: "komebu", // コメ部専用
        targetId, // コメ部のID
        isPublish: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(db, "post"), postData);

      // 写真があればアップロード
      for (const file of photos) {
        const imageRef = ref(storage, `komebu/${docRef.id} - ${file.file.name}`);
        const snap = await uploadBytes(imageRef, file.file);
        const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

        await addDoc(collection(db, "postImage"), {
          url,
          path: snap.ref.fullPath,
          id: docRef.id,
          creator: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          type: "komebu",
        });
      }

      displayNotification({
        message: "投稿しました。",
        type: "success",
      });

      setData({ message: "", loading: false });
      onClose(); // ダイアログを閉じる
    } catch (err) {
      console.error("投稿エラー:", err);
      setData({ ...data, loading: false, error: "投稿に失敗しました。" });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>コメ部に投稿</DialogTitle>
      <DialogContent dividers>
        {/* ユーザー情報 */}
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={user?.avatar} />
          <Typography variant="subtitle1" ml={2}>
            {user?.lastName} {user?.firstName}
          </Typography>
        </Box>

        {/* メッセージ入力 */}
        <TextareaAutosize
          minRows={5}
          maxRows={8}
          placeholder="コメ部にメッセージを投稿..."
          style={{ width: "100%", fontSize: "16px", padding: "8px" }}
          value={message}
          onChange={handleChange}
        />

        {/* メディアアップロード */}
        <InputMediaOnFeed photos={photos} setPhotos={setPhotos} />
      </DialogContent>

      {/* アクションボタン */}
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          キャンセル
        </Button>
        <Button
          onClick={doRegist}
          variant="contained"
          color="primary"
          disabled={loading || !message}
        >
          {loading ? "投稿中..." : "投稿する"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KomebuAddPostDialog;
